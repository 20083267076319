// --------------------------------------------------------------------------------
//  Pistache - Configuration & données statiques
// --------------------------------------------------------------------------------

const siteURL  = 'https://www.pistache.org';
const strmURL  = 'https://rpstrm.pistache.org';

const prms     = {

  // === player ===

  sampleRate      : 44100,
  ////  sourceURL       : strmURL + ':9443/pistache.mp3?nocache=',

  // URL serveur audio players intégré, et mp3 / aac externes
  sourceURL       : { web: strmURL + ':9443/pistache.mp3?nocache=',
                      mp3: strmURL + ':9443/pistache.mp3',
                      aac: strmURL + ':9443/pistache.aac'
                    },

  // URL fichier audio contenant l'intro pistache
  introURL        : '/snd/webrp-intro.mp3',

  // paramètres des filtres audio
  eqFilters       : [{ t: 'lowshelf', f: 140, q: 0}, { t: 'peaking', f: 250, q: 0.7}, { t: 'peaking', f: 800, q: 0.5},
                     { t: 'peaking', f: 2800, q: 0.7}, { t: 'highshelf', f: 4800, q: 0}],

  // preset equaliser
  eqPresets       : { 'default'  : [1, 0, 0, 0, 0],
                      'linear'   : [0, 0, 0, 0, 0],
                      'loudness' : [5, -1, -1, -1, 0],
                      'vocals'   : [-2, -1, 2, 3, -1],
                    },

  // meters
  metersChannels  : ['left channel', 'right channel'],

  // noutons barre de menu
  optButtons      : { mute: { ico: 'volume-mute', descOn: 'mute off', descOff: 'mute' },
//                      base: { ico: 'list', descOn: 'base de titres', descOff: 'base de titres' },
                      meters: { ico: 'signal', descOn: 'disable meters', descOff: 'enable meters' },
                      equalizer: { ico: 'sliders-h', descOn: 'hide equalizer', descOff: 'show equalizer' },
                    },

  // === onAir / lastDiff ===

  // - URL accès images pochettes sur site RP
  coversBaseURL   : siteURL + '/covers/',

};

export { prms }
