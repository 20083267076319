import Vue from 'vue'
import App from './App.vue'
///import VueMeta from 'vue-meta'
import VueSocketio from 'vue-socket.io'
import io from 'socket.io-client'

import 'element-ui/lib/theme-chalk/reset.css'
import './plugins/element.js'
import './plugins/fontawesome'

////Vue.prototype.$eventBus = new Vue ();

// import 'vue-awesome/icons'
// import Icon from 'vue-awesome/components/Icon'
// Vue.component('v-icon', Icon)

///Vue.use (VueMeta);

Vue.config.productionTip = false

// connexion serveur websocket
Vue.use(new VueSocketio ({
  debug: false,
  connection: io ('https://www.pistache.org'),
  //  options: { path: '/' },
  transports: ['websocket'],
}))

new Vue({
  render: h => h (App),
}).$mount ('#app')
