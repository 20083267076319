
<template>
<div>

  <!-- on air -->
  <div class="col-top" style="height: 100%;">

    <div class="box" ref="imageBox" :style="'height: ' + (options.equalizer ? '70%' : '80%') + '; margin-bottom: 0px;'">
      <div class="imageBox" :style="imgBoxCss" @click="$emit('toggleOption', 'equalizer')">

        <div class="imgDef"
             :style="'padding-top:' + ((options.equalizer ? 50 : 72) + (displayMode == 1 ? 30 : 0)) + 'px; opacity: ' + compImgTab[0].opacity + ';'">
          <font-awesome-icon icon="music" />
        </div>

        <div v-for="im,idx of compImgTab.slice(1,3)" :key="idx" style="height: 100%; width: 100%; position: absolute; ">
          <img :src="im.src" :style="'position: absolute; filter: blur(40px); opacity: ' + im.opacity + ';'">
          <img :src="im.src" :style="'position: absolute; filter: blur(0); opacity: ' + im.opacity + ';'">
        </div>

      </div>

      <div v-if="displayMode == 2" :class="'backBtn ' + (options.equalizer ? 'right' : '')" @click="$emit('toggleOption', 'equalizer')">
        &#60;&#60;
      </div>
    </div>

    <!-- <div class="box" :class="'box ' + (largeMode ? '' : 'lastDiff') + ' small'" style="height: 80px; margin-top: -18px; padding-top: 9px;"> -->
      <el-tooltip :disabled="false" :open-delay="500" :hide-after="2000" effect="dark" content="save to clipboard">
        <div class="box small" :style="'height: ' + (options.equalizer ? '31%' : '20%') + '; padding-top: 8px; margin-bottom: 0px;'"
             @click="copyTitleToClipboard()">
          <div class="text" :style="'font-size: 17px; font-weight: 600; margin-top: 10px; opacity:' + (imgReact == 1 ? 0 : 1)">{{ onAir.interprete }}</div>
          <div class="text" :style="'color: #bbb; opacity:' + (imgReact == 1 ? 0 : 1)">{{ onAir.titre }}</div>
        </div>
      </el-tooltip>

      <div :class="'notify' + (notify ? ' on' : '')"><font-awesome-icon icon="check" color="#aaa" /> saved to clipboard</div>

    </div>

</div>
</template>

<script>

// --------------------------------------------------------------------------------
import { prms } from '../pistache-cfg.js'

const onAirDef = { titre: '- - - - -', interprete: '', duree: '' }

export default {
  name: 'onAir',

  // Property types : String, Number, Boolean, Array, Object, Date, Function, Symbol
  props: {
    displayMode     : { type: Number, default: 2 },
    options         : { type: Object, default: function () { return ({ equalizer: false }); } },
    lastDiff        : { type: Array, default: function () { return ([]); } },
  },

  data: function () { return ({
    prms       : prms,
    notify    : false,
    onAir      : onAirDef,
    imgBoxCss  : '',

    imgTab     : [{ src: null, opacity: 0.8 }, { src: '', opacity: 0 }, { src: '', opacity: 0 }],
    imgIndex   : 0,  // 0 = image defaut, 1 = image 1, 2 = image 2
    imgReact   : 0,
  }); },

  mounted: function () {

    window.addEventListener ('resize', this.onResize);
    this.mkImgBoxCss ();
  },

  methods: {

    onResize: function () {
      let that = this;
      setTimeout (function () { that.mkImgBoxCss (); }, 100);
    },

    mkImgBoxCss: function () {

      if (this.$refs.imageBox) {
        const cx = this.$refs.imageBox.clientWidth;
        const cy = this.$refs.imageBox.clientHeight;

        let css = 'position: absolute; opacity: ' + (this.options.equalizer ? 0.82 : 0.8) + ';';

        css += 'font-size: ' + (this.options.equalizer ? '48px' : '120px') + ';';

        if (this.options.equalizer) {
          css += 'height: ' + cy * 0.92 + 'px; width: ' + cy * 0.94 + 'px;';
        }
        else {
          css += 'height: ' + cy * 0.95 + 'px; width: ' + cy * 0.99 + 'px;';
        }

        css += 'margin-left: ' + (cx - cy) / 2 + 'px;';
        this.imgBoxCss = css;
      }
      else { this.imgBoxCss = ''; }
    },

    copyTitleToClipboard: function () {

      navigator.clipboard.writeText (this.onAir.interprete + ' - ' + this.onAir.titre);
      let that = this;  that.notify = true;
      setTimeout (function () { that.notify = false; }, 1500);
    },
  },

  watch: {

    options: {
      deep: true,
      handler: function () {
        this.$nextTick (function () {  this.mkImgBoxCss (); } );
      },
    },

    lastDiff: function () {
      let that = this;

      // cas liste lastDiff vide
      if (this.lastDiff.length == 0) { this.onAir = onAirDef;  this.imgIndex = 0;  return; }

      // référence sur nouveau titre courant (onAir)
      const x = this.lastDiff[that.lastDiff.length - 1];

      // image non disponible
      if (x.image == '') { this.imgIndex = 0; }

      // image disponible
      else {
        this.imgIndex = (this.imgIndex == 1) ? 2 : 1;
        this.imgTab[this.imgIndex] = { src: this.prms.coversBaseURL + x.image, opacity: 0 };
      }
      this.imgReact = 1;

      // effacement titre / interprète
////      this.onAir = { interprete: '', titre: '' };

      // fondu après délai (chargement image)
      setTimeout (function () {

        // forçage refresh compImgTab dans computed
        that.imgReact = 0;

        // titre / interprete
        that.onAir = { interprete: x.interprete, titre: x.titre };

        // masquage ancienne image et affichage image courante
        for (let i = 0;  i < that.imgTab.length;  i ++) { that.imgTab[i].opacity = 0; }
        that.imgTab[that.imgIndex].opacity = 0.8;

      }, 1000);
    },
  },

  computed: {
    compImgTab: function () { this.imgReact;  return (this.imgTab); }
  },
}

// --------------------------------------------------------------------------------

</script>

<style lang="scss" scoped>

.imageBox {
  background: #000;
  z-index: 1;
  margin-top: 13px;
  /* opacity: 0.8; */

  &>.imgDef {
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(#777 0%, #222 100%);
    display: inline-block;
    text-align: center;
    color: #aaa;
    transition: opacity ease-in-out 1.5s;
  }

  &>div>img {
    height: 100%;
    width: 100%;
    transition: opacity ease-in-out 1.5s;
  }
}

.backBtn {
  position: absolute;
  margin-top: 270px;
  margin-left: 3%;
  cursor: pointer;

  font-size: 24px;
  color: #a60;
  transform: scale(0.7, 2.2);
  opacity: 0.5;

  &.right {
    margin-top: 145px;
    margin-left: 91%;
    transform: rotate(180deg) scale(0.6, 1.85);
  }

  &:hover {
    opacity: 1;
  }
  transition: opacity ease-in-out 0.2s;
}

.text {
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #ccc;
  padding-top: 2px;
  transition: opacity ease-in-out 1.5s;
}

</style>
