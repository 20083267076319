var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"dorrough"},[_c('svg',{attrs:{"height":_vm._canvasHeight,"width":_vm._canvasWidth}},[_c('defs',[_c('linearGradient',{attrs:{"id":"captionHighlight"}},[_c('stop',{attrs:{"offset":"0%","stop-color":"#333"}}),_c('stop',{attrs:{"offset":"50%","stop-color":_vm.captionHighlightColor}}),_c('stop',{attrs:{"offset":"100%","stop-color":"#333"}})],1),_c('linearGradient',{attrs:{"id":"captionHighlightMid"}},[_c('stop',{attrs:{"offset":"0%","stop-color":"transparent"}}),_c('stop',{attrs:{"offset":"15%","stop-color":"#fff"}}),_c('stop',{attrs:{"offset":"85%","stop-color":"#fff"}}),_c('stop',{attrs:{"offset":"100%","stop-color":"transparent"}})],1)],1),_vm._l((_vm.legendArcPrms.haut),function(item,idx){return _c('path',{key:idx + item.start.x,attrs:{"d":'M' + item.start.x + ' ' + item.start.y + ' A' + _vm.legendRayon + ' ' + _vm.legendRayon + ' 0 '
                + item.largeArcFlag + ' 1 ' + item.end.x + ' ' + item.end.y,"stroke-width":_vm.legendWidth,"stroke":item.color,"fill":"none","transform":'translate(' + _vm.posTrans.x + ',' + _vm.posTrans.y + '), rotate(' + _vm._rotationAngl + ',' + _vm.gaugePos.x + ',' + _vm.gaugePos.y + ')'}})}),_vm._l((_vm.legendArcPrms.bas),function(item,idx){return _c('path',{key:idx + item.start.x,attrs:{"d":'M' + item.start.x + ' ' + item.start.y + ' A' + (_vm.legendRayon - _vm.gaugeWidth - 2 * _vm.legend2GaugeSpace - 3) + ' '
                + (_vm.legendRayon - _vm.gaugeWidth - 2 * _vm.legend2GaugeSpace - 3) + ' 0 '
                + item.largeArcFlag + ' 1 ' + item.end.x + ' ' + item.end.y,"stroke-width":_vm.legendWidth,"stroke":item.color,"fill":"none","transform":'translate(' + _vm.posTrans.x + ',' + _vm.posTrans.y + '), rotate(' + _vm._rotationAngl + ',' + _vm.gaugePos.x + ',' + _vm.gaugePos.y + ')'}})}),_vm._l((_vm.scaleData),function(item,idx){return _c('text',{key:idx,attrs:{"x":(_vm._canvasWidth / 2),"y":10,"font-family":"Verdana","font-size":9,"fill":_vm.captionColor,"transform":'rotate(' + (item.a * _vm._apertureAngl) + ',' + (_vm._canvasWidth / 2) + ',' + _vm.legendRayon + ')'}},[_vm._v(_vm._s(item.t))])}),_c('path',{attrs:{"d":'M' + _vm.gaugeStart.x + ' ' + _vm.gaugeStart.y + ' A' + _vm.gaugeRayon + ' ' + _vm.gaugeRayon + ' 0 '
                + _vm.largeArcFlagBg + ' 1 ' + _vm.gaugeEnd.x + ' ' + _vm.gaugeEnd.y,"stroke-width":_vm.gaugeWidth,"stroke":_vm.gaugeBgColor,"fill":"none","transform":'translate(' + _vm.posTrans.x + ',' + _vm.posTrans.y + '), rotate(' + _vm._rotationAngl + ',' + _vm.gaugePos.x + ',' + _vm.gaugePos.y + ')'}}),_c('clipPath',{attrs:{"id":"gauge-mask-clip","clipPathUnits":"userSpaceOnUse"}},[_c('rect',{staticStyle:{"transition":"transform 0.1s"},attrs:{"x":(_vm.gaugePos.x - _vm.gaugeRayon),"y":(_vm.gaugePos.y - _vm.gaugeRayon + _vm.posTrans.y - _vm.gaugeWidth),"width":_vm.gaugeRayon,"height":_vm.gaugeRayon + _vm.gaugeWidth,"transform":'rotate(' + (_vm.currAngl * _vm.apertureAngl + _vm._rotationAngl) + ',' + (_vm.posTrans.x + _vm.gaugePos.x) + ',' + (_vm.posTrans.y + _vm.gaugePos.y) + ')'}}),_c('rect',{staticStyle:{"transition":"transform 0.1s"},attrs:{"x":(_vm.gaugePos.x - _vm.gaugeHashSize * _vm.peakWidth),"y":(_vm.gaugePos.y - _vm.gaugeRayon + _vm.posTrans.y - _vm.gaugeWidth),"width":(_vm.gaugeHashSize * _vm.peakWidth),"height":_vm.gaugeRayon + _vm.gaugeWidth,"transform":'rotate(' + (_vm.peakAngl * _vm.apertureAngl + _vm._rotationAngl) + ',' + (_vm.posTrans.x + _vm.gaugePos.x) + ',' + (_vm.posTrans.y + _vm.gaugePos.y) + ')'}})]),_c('g',{attrs:{"clip-path":"url(#gauge-mask-clip)"}},_vm._l((_vm.gaugeArcPrms),function(item,idx){return _c('path',{key:idx + item.start.x,attrs:{"d":'M' + item.start.x + ' ' + item.start.y + ' A' + _vm.gaugeRayon + ' ' + _vm.gaugeRayon + ' 0 '
                  + item.largeArcFlag + ' 1 ' + item.end.x + ' ' + item.end.y,"stroke-width":_vm.gaugeWidth,"stroke":item.color,"fill":"none","transform":'translate(' + _vm.posTrans.x + ',' + _vm.posTrans.y + '), rotate(' + _vm._rotationAngl + ',' + _vm.gaugePos.x + ',' + _vm.gaugePos.y + ')'}})}),0),(_vm.gaugeHash)?_c('path',{attrs:{"d":'M' + _vm.gaugeStart.x + ' ' + _vm.gaugeStart.y
                + ' A' + _vm.gaugeRayon + ' ' + _vm.gaugeRayon + ' 0 ' + _vm.largeArcFlag + ' 1 '
                + _vm.gaugeEnd.x + ' ' + _vm.gaugeEnd.y,"stroke-width":_vm.gaugeWidth,"stroke-dasharray":_vm.gaugeHashSize,"stroke":"#000","fill":"none","transform":'translate(' + _vm.posTrans.x + ',' + _vm.posTrans.y + '), rotate(' + _vm._rotationAngl + ',' + _vm.gaugePos.x + ',' + _vm.gaugePos.y + ')'}}):_vm._e(),_c('text',{attrs:{"x":(_vm._canvasWidth / 2 + _vm.unitPosAdjust.x),"y":_vm.unitPosAdjust.y,"font-family":"Verdana","font-size":12,"letter-spacing":2,"fill":_vm.captionColor,"text-anchor":"middle","alignment-baseline":"middle"}},[_vm._v("db")]),(_vm.captionHighlight)?_c('g',[_c('rect',{attrs:{"x":_vm.gaugeStart.x - 10,"y":(_vm.captionPos.y - _vm.captionFontSize - 5),"height":1,"width":(_vm.gaugeEnd.x + 20 - _vm.gaugeStart.x),"fill":"url(#captionHighlight)"}}),_c('rect',{attrs:{"x":(_vm.gaugeStart.x - 10),"y":(_vm.captionPos.y - _vm.captionFontSize - 2),"stroke-dasharray":"2","height":2 * _vm.captionFontSize - 5,"width":(_vm.gaugeEnd.x - _vm.gaugeStart.x + 20),"fill":"url(#captionHighlightMid)","opacity":"0.05"}}),_c('rect',{attrs:{"x":_vm.gaugeStart.x - 10,"y":(_vm.captionPos.y + _vm.captionFontSize - 6),"height":1,"width":(_vm.gaugeEnd.x + 20 - _vm.gaugeStart.x),"fill":"url(#captionHighlight)"}})]):_vm._e(),(_vm.captionVisible)?_c('text',{attrs:{"x":_vm.captionPos.x,"y":_vm.captionPos.y,"font-size":_vm._captionFontSize,"letter-spacing":3,"fill":(_vm.captionHighlight ? '#ccc' : _vm.captionColor),"text-anchor":"middle"}},[_vm._v(_vm._s(_vm.captionText))]):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }