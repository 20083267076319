var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"clockDiv",staticClass:"clock"},[((_vm.clock.secCour >= 0))?_c('svg',{attrs:{"height":(_vm.clock.size + _vm.clock.bSize) * 2,"width":(_vm.clock.size + _vm.clock.bSize) * 2}},[_vm._l((_vm.clock.secPts),function(pt){return _c('g',{key:'secPts' + pt.x + pt.y + pt.on},[(pt.on)?_c('polygon',{attrs:{"points":(pt.x - _vm.clock.ledDiam) + ' ' + (pt.y - _vm.clock.secLgRatio*_vm.clock.ledDiam) + ', '
                                       + (pt.x + _vm.clock.ledDiam) + ' ' + (pt.y - _vm.clock.secLgRatio*_vm.clock.ledDiam) + ', '
                                       + (pt.x + _vm.clock.ledDiam) + ' ' + (pt.y + _vm.clock.secLgRatio*_vm.clock.ledDiam) + ', '
                                       + (pt.x - _vm.clock.ledDiam) + ' ' + (pt.y + _vm.clock.secLgRatio*_vm.clock.ledDiam),"fill":_vm.clock.color,"transform":'rotate(' + pt.a + ' ' + pt.x + ' ' + pt.y +')'}}):_vm._e()])}),_vm._l((_vm.clock.sec5Pts),function(pt){return _c('g',{key:'sec5Pts' + pt.x + pt.y + pt.on},[_c('polygon',{attrs:{"points":(pt.x - _vm.clock.ledDiam) + ' ' + (pt.y - _vm.clock.ledDiam) + ', '
                          + (pt.x + _vm.clock.ledDiam) + ' ' + (pt.y - _vm.clock.ledDiam) + ', '
                          + (pt.x + _vm.clock.ledDiam) + ' ' + (pt.y + _vm.clock.ledDiam) + ', '
                          + (pt.x - _vm.clock.ledDiam) + ' ' + (pt.y + _vm.clock.ledDiam),"fill":_vm.clock.color,"transform":'rotate(' + pt.a + ' ' + pt.x + ' ' + pt.y +')'}})])}),_vm._l((_vm.clock.twoPts),function(pt){return _c('g',{key:'twoPts' + pt.x + pt.y},[(_vm.clock.timerTic <= 5)?_c('polygon',{attrs:{"points":(pt.x - 0.87*_vm.clock.ledDiam) + ' ' + (pt.y - _vm.clock.ledDiam) + ', '
                                                     + (pt.x + 1.13*_vm.clock.ledDiam) + ' ' + (pt.y - _vm.clock.ledDiam) + ', '
                                                     + (pt.x + 0.87*_vm.clock.ledDiam) + ' ' + (pt.y + _vm.clock.ledDiam) + ', '
                                                     + (pt.x - 1.13*_vm.clock.ledDiam) + ' ' + (pt.y + _vm.clock.ledDiam),"fill":_vm.clock.color}}):_vm._e()])}),_vm._l((_vm.clock.HHMM),function(ch){return _c('g',{key:'chiffre' + ch.x,attrs:{"set1":_vm.mx = _vm.clock.size + ch.x,"set2":_vm.my = _vm.clock.bSize + _vm.clock.size}},_vm._l((_vm.clock.segments),function(seg){return _c('g',{key:'seg' + seg.x + seg.y},_vm._l((_vm.clock.segm[seg.type]),function(pt){return (seg.chiffres[ch.v])?_c('g',{key:'point' + pt.x + pt.y,attrs:{"set1":_vm.px = (_vm.mx + seg.x + pt.x),"set2":_vm.py = (_vm.my + seg.y + pt.y)}},[_c('polygon',{attrs:{"points":(_vm.px - 0.87*_vm.clock.ledDiam) + ' ' + (_vm.py - _vm.clock.ledDiam) + ', '
                              + (_vm.px + 1.13*_vm.clock.ledDiam) + ' ' + (_vm.py - _vm.clock.ledDiam) + ', '
                              + (_vm.px + 0.87*_vm.clock.ledDiam) + ' ' + (_vm.py + _vm.clock.ledDiam) + ', '
                              + (_vm.px - 1.13*_vm.clock.ledDiam)  + ' ' + (_vm.py + _vm.clock.ledDiam),"fill":_vm.clock.color}})]):_vm._e()}),0)}),0)})],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }