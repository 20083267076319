
<template>
<div>

  <div class="col-top box" style="height: 100%; width: 100%;">

    <!-- titres diffusés -->
    <div class="col-top lastList" ref="lastList">

      <div class="line-center item" v-for="titre,idx of lastDiff.slice(0, lastDiff.length - 1)" :key="idx" style="justify-content: flex-start">
        <div class="imageBox">
          <img :src="prms.coversBaseURL + titre.thumb" v-if="titre.thumb">

          <div class="imgDef" v-if="!titre.thumb">
            <font-awesome-icon icon="music" style="color:#aaa; height:15px; width:15px; margin-top:15px;" />
          </div>
        </div>

        <div class="col-top" style="width: 100%; overflow: hidden;" @click="copyTitleToClipboard (titre)">
          <div class="text">{{ titre.interprete }}</div>
          <div class="text line-center">
            <div style="color: #ccc; margin-right: 4px;">{{ titre.titre.substr(0, 80) }}</div>
            <div style="margin-right: 4px; color: #f90;">{{ titre.diffDate }}</div>
          </div>
        </div>
      </div>

    </div>

    <div v-if="notify > 0" :class="'notify' + (notify > 1 ? ' on' : '')">
      <font-awesome-icon icon="check" color="#aaa" /> saved to clipboard
    </div>
  </div>

</div>
</template>

<script>

// --------------------------------------------------------------------------------
import { prms } from '../pistache-cfg.js'

export default {
  name: 'lastDiff',

  components: { },

  // Property types : String, Number, Boolean, Array, Object, Date, Function, Symbol
  props: {
    lastDiff   : { type: Array, default: function () { return ([]); } },
  },

  data: function () { return ({
    prms       : prms,
    notify     : 0,
  }); },

  methods: {

    copyTitleToClipboard: function (titre) {

      navigator.clipboard.writeText (titre.interprete + ' - ' + titre.titre);
      let that = this;

      that.notify = 1;  setTimeout (function () { that.notify = 2; }, 1);

      setTimeout (function () {
        that.notify = 1;  setTimeout (function () { that.notify = 0; }, 300);
      }, 1500);
    },
  },

  watch: {

    lastDiff: function (val) {
      let that = this;
      this.$nextTick (function () { that.$refs.lastList.scrollTop = that.$refs.lastList.scrollHeight; });
    },
  },
}

// --------------------------------------------------------------------------------

</script>

<style lang="scss" scoped>

.lastList {
  margin: 16px 0px 10px 8px;
  overflow-x: hidden;

  &>.item {
    border-bottom: 1px solid #444;
    min-height: 52px;

    padding-top: 1px;
    padding-bottom: 1px;
    cursor: pointer;

    &>.col-top>.text {
      white-space: nowrap;
      overflow: hidden;
      font-size: 13px;
      font-weight: 200;
      color: #eee;

      margin-top: 1px;
      margin-bottom: 2px;
      padding-left: 10px;
      padding-right: 10px;
    }

    &>.imageBox {
      height: 48px;
      width: 50px;
      min-width: 52px;

      background: #510;

      &>.imgDef {
        height: 100%;
        width: 100%;
        background: linear-gradient(#666 0%, #111 100%);
        display: inline-block;
        text-align: center;
      }

      &>img {
        height: 100%;
        width: 100%;
        opacity: 0.8;
      }
    }
  }
}

</style>
