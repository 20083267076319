<template>
<div>
  <div class="clock" ref="clockDiv">

    <svg v-if="(clock.secCour >= 0)" :height="(clock.size + clock.bSize) * 2" :width="(clock.size + clock.bSize) * 2">

      <g v-for="pt in clock.secPts" :key="'secPts' + pt.x + pt.y + pt.on">
	<!-- <circle v-if="pt.on" :cx="pt.x" :cy="pt.y" :r="clock.ledDiam" -->
	<!-- 	fill="url(#led-gradient)" :stroke-width="1" stroke="#ff7700"/> -->
        <polygon v-if="pt.on" :points="(pt.x - clock.ledDiam) + ' ' + (pt.y - clock.secLgRatio*clock.ledDiam) + ', '
                                       + (pt.x + clock.ledDiam) + ' ' + (pt.y - clock.secLgRatio*clock.ledDiam) + ', '
                                       + (pt.x + clock.ledDiam) + ' ' + (pt.y + clock.secLgRatio*clock.ledDiam) + ', '
                                       + (pt.x - clock.ledDiam) + ' ' + (pt.y + clock.secLgRatio*clock.ledDiam)"
                 :fill="clock.color" :transform="'rotate(' + pt.a + ' ' + pt.x + ' ' + pt.y +')'" />
      </g>
      <g v-for="pt in clock.sec5Pts" :key="'sec5Pts' + pt.x + pt.y + pt.on">
	<!-- <circle :cx="pt.x" :cy="pt.y" :r="clock.ledDiam" -->
	<!-- 	fill="url(#led-gradient)" :stroke-width="1" stroke="#ff7700"/> -->
        <polygon :points="(pt.x - clock.ledDiam) + ' ' + (pt.y - clock.ledDiam) + ', '
                          + (pt.x + clock.ledDiam) + ' ' + (pt.y - clock.ledDiam) + ', '
                          + (pt.x + clock.ledDiam) + ' ' + (pt.y + clock.ledDiam) + ', '
                          + (pt.x - clock.ledDiam) + ' ' + (pt.y + clock.ledDiam)"
                 :fill="clock.color" :transform="'rotate(' + pt.a + ' ' + pt.x + ' ' + pt.y +')'" />
      </g>
      <g v-for="pt in clock.twoPts" :key="'twoPts' + pt.x + pt.y">
	<!-- <circle v-if="clock.timerTic <= 5" :cx="pt.x" :cy="pt.y" :r="clock.ledDiam" -->
	<!-- 	fill="url(#led-gradient)" :stroke-width="1" stroke="#ff7700"/> -->
        <polygon v-if="clock.timerTic <= 5" :points="(pt.x - 0.87*clock.ledDiam) + ' ' + (pt.y - clock.ledDiam) + ', '
                                                     + (pt.x + 1.13*clock.ledDiam) + ' ' + (pt.y - clock.ledDiam) + ', '
                                                     + (pt.x + 0.87*clock.ledDiam) + ' ' + (pt.y + clock.ledDiam) + ', '
                                                     + (pt.x - 1.13*clock.ledDiam) + ' ' + (pt.y + clock.ledDiam)"
                 :fill="clock.color" />
      </g>

      <g v-for="ch in clock.HHMM" :set1="mx = clock.size + ch.x" :set2="my = clock.bSize + clock.size" :key="'chiffre' + ch.x">
	<g v-for="seg in clock.segments" :key="'seg' + seg.x + seg.y">
	  <g v-if="seg.chiffres[ch.v]" v-for="pt in clock.segm[seg.type]" :key="'point' + pt.x + pt.y"
             :set1="px = (mx + seg.x + pt.x)" :set2="py = (my + seg.y + pt.y)">
	    <!-- <circle :cx="mx + seg.x + pt.x" :cy="my + seg.y + pt.y" :r="clock.ledDiam" -->
	    <!--         fill="url(#led-gradient)" :stroke-width="1" stroke="#cc5500" /> -->
            <polygon :points="(px - 0.87*clock.ledDiam) + ' ' + (py - clock.ledDiam) + ', '
                              + (px + 1.13*clock.ledDiam) + ' ' + (py - clock.ledDiam) + ', '
                              + (px + 0.87*clock.ledDiam) + ' ' + (py + clock.ledDiam) + ', '
                              + (px - 1.13*clock.ledDiam)  + ' ' + (py + clock.ledDiam)"
                     :fill="clock.color" />
	  </g>
	</g>
      </g>
    </svg>
  </div>
</div>

</template>


<script>

// --------------------------------------------------------------------------------


export default {
  name: 'clock',

  // Property types : String, Number, Boolean, Array, Object, Date, Function, Symbol
  props: {
///    width            : { type: String, default: '95%' },
  },

  data: function() {
    return{
      clock: {
	// tailles objets - cf. clockInit
	size: 0,  bSize: 0,  digitSize: 0,  digitSpace: 0,
	hhmmSpace: 0,  segSpace: 0,  incl: 0,  ledDiam: 0,

	// coordonnées et état des leds des secondes, des leds 5 secondes et des leds deux-points
	secPts: [], sec5Pts: [], twoPts: [],

	// compteur secondes courant, timerTic pour clignotement deux-points
	secCour: -1, minCour : -1, timerTic: 0,

	// segment vertical/horizontal
	segm: { V: [], H: [] },

	// position (x, y), type (H/V) et appartenance à un chiffre (0-9) de chaque segment - cf. clockInit
	segments: { },

	// position et valeur courante des chiffres heures et minutes - cf. clockInit
	HHMM: [],
      },
    }
  },

  created: function() {
  },

  mounted: function() {
    window.addEventListener('resize', this.onResize);
    this.clockInitStatic();
    this.clockInitTimer();
  },

  beforeDestroy: function() {
    window.removeEventListener('resize', this.onResize);
  },

  watch: {
  },

  methods: {

    // resize
    onResize: function() {
//      console.log('RESIZE');
      this.clockInitStatic();
      this.clockTimerTic(this.clock);
    },

    // clock : initialisation des paramètres statiques
    clockInitStatic: function() {
      const ck = this.clock;
      ck.size        = (this.$refs.clockDiv.clientHeight / 2.2);  // taille 1/2 côté canvas horloge
      ck.bSize       = 0.1 * ck.size;          // espace entre horloge et bordures du carré
      ck.digitSize   = 45 * (ck.size/400);  // largeur (= 1/2 hauteur) digit en points
      ck.digitSpace  = 115 * (ck.size/400);    // écartement entre 2 digits heures ou minutes
      ck.hhmmSpace   = 210 * (ck.size/400);    // écartement entre digits heures et minutes
      ck.segSpace    =  34.1 * (ck.size/400);    // espacement entre segments
      ck.incl        =   3 * (ck.size/400);    // inclinaison des segments en point par led
      ck.ledDiam     =   8 * (ck.size/400);    // diamètre leds
      ck.secLgRatio  = 1.8;                    // ratio longueur trotteuse
      // ck.color       = '#ff6000';
      ck.color       = '#f70';

      // position (x, y), type (H/V) et appartenance à un chiffre (0-9) de chaque segment
      // ck.segments = {
      //   1: { x:  ck.segSpace + ck.incl*2.1,  y: -ck.segSpace + ck.incl*0.2,  type: 'V', chiffres: [1, 1, 1, 1, 1, 0, 0, 1, 1, 1] },
      //   2: { x:  ck.segSpace - ck.incl*2.1,  y:  ck.segSpace + ck.incl*0.2,  type: 'V', chiffres: [1, 1, 0, 1, 1, 1, 1, 1, 1, 1] },
      //   3: { x:            0 - ck.incl*3.8,  y:  ck.segSpace*2,              type: 'H', chiffres: [1, 0, 1, 1, 0, 1, 1, 0, 1, 1] },
      //   4: { x: -ck.segSpace - ck.incl*2.1,  y:  ck.segSpace - ck.incl*0.2,  type: 'V', chiffres: [1, 0, 1, 0, 0, 0, 1, 0, 1, 0] },
      //   5: { x: -ck.segSpace + ck.incl*2.1,  y: -ck.segSpace - ck.incl*0.2,  type: 'V', chiffres: [1, 0, 0, 0, 1, 1, 1, 0, 1, 1] },
      //   6: { x:            0 + ck.incl*3.8,  y: -ck.segSpace*2,              type: 'H', chiffres: [1, 0, 1, 1, 0, 1, 1, 1, 1, 1] },
      //   7: { x:            0 - ck.incl*0.05, y:              0,              type: 'H', chiffres: [0, 0, 1, 1, 1, 1, 1, 0, 1, 1] },
      // };

      ck.segments = {
	1: { x:  ck.segSpace + ck.incl*1.5,  y: -ck.segSpace + ck.incl*0,  type: 'V', chiffres: [1, 1, 1, 1, 1, 0, 0, 1, 1, 1] },
	2: { x:  ck.segSpace - ck.incl*1.5,  y:  ck.segSpace + ck.incl*0,  type: 'V', chiffres: [1, 1, 0, 1, 1, 1, 1, 1, 1, 1] },
	3: { x:            0 - ck.incl*3,    y:  ck.segSpace*2,              type: 'H', chiffres: [1, 0, 1, 1, 0, 1, 1, 0, 1, 1] },
	4: { x: -ck.segSpace - ck.incl*1.5,  y:  ck.segSpace - ck.incl*0,  type: 'V', chiffres: [1, 0, 1, 0, 0, 0, 1, 0, 1, 0] },
	5: { x: -ck.segSpace + ck.incl*1.5,  y: -ck.segSpace - ck.incl*0,  type: 'V', chiffres: [1, 0, 0, 0, 1, 1, 1, 0, 1, 1] },
	6: { x:            0 + ck.incl*3,    y: -ck.segSpace*2,              type: 'H', chiffres: [1, 0, 1, 1, 0, 1, 1, 1, 1, 1] },
	7: { x:            0 - ck.incl*0,    y:              0,              type: 'H', chiffres: [0, 0, 1, 1, 1, 1, 1, 0, 1, 1] },
      };

      // position et valeur courante des chiffres heures et minutes
      ck.HHMM = [{ x: ck.bSize - ck.hhmmSpace/2 - ck.digitSpace, v: 8 }, { x: ck.bSize - ck.hhmmSpace/2, v: 8 },
		 { x: ck.bSize + ck.hhmmSpace/2, v: 8 }, { x: ck.bSize + ck.hhmmSpace/2 + ck.digitSpace, v: 8 }];

      // leds secondes
      ck.secPts = [];
      for (let i = 0;  i <= 59;  i++) {
	const ang = (Math.PI * i) / 30;
	ck.secPts[i] = { x: ck.bSize + ck.size + (ck.size * (365/400) * Math.sin(ang)),
			 y: ck.bSize + ck.size - (ck.size * (365/400) * Math.cos(ang)),
                         a: 6*i, on: false };
      }

      // leds 5 minutes extérieurs
      ck.sec5Pts = [];
      for (let i = 0;  i <= 11;  i++) {
	const ang = (Math.PI * i) / 6;
	ck.sec5Pts[i] = { x: ck.bSize + ck.size + (ck.size * (398/400) * Math.sin(ang)),
			  y: ck.bSize + ck.size - (ck.size * (398/400) * Math.cos(ang)), a: 30*i};
      }

      // - segment horizontal
      ck.segm.H = [];
      for (let i = 0;  i <= 3;  i++) {
	ck.segm.H[i] = { x: (i - 1.5) * ck.digitSize/2, y: 0 };
      }

      // - segment vertical
      ck.segm.V = [];
      for (let i = 0;  i <= 3;  i++) {
	ck.segm.V[i] = { x: ck.incl * (1.5 - i), y: (i - 1.5) * ck.digitSize/2 };
      }

      // séparateur 2 points
      ck.twoPts = [{ x: ck.bSize + ck.size + ck.incl * 1.5, y: ck.bSize + ck.size - (ck.digitSize * 0.7) },
		   { x: ck.bSize + ck.size - ck.incl * 1.5, y: ck.bSize + ck.size + (ck.digitSize * 0.7) },]

      // force refresh
      ck.secCour = -1;
    },

    // clock : init timer
    clockInitTimer: function() {
      setInterval(this.clockTimerTic, 90, this.clock);
    },

    // clock : timer tic
    clockTimerTic: function(ck) {

      const dt = new Date();
      const sec = dt.getSeconds();

      // changement de seconde
      if (sec != ck.secCour) {

	// sec = 0, effacement vague (ou initialisation, ck.secCour = -1)
	if ((sec == 0) || (ck.secCour == -1)) {
	  for (let i = 0;  i <= 59;  i++) { ck.secPts[i].on = (i <= sec); }

	  const min = dt.getMinutes();

	  // changement de minute
	  if (min != ck.secMin) {
	    const hour = dt.getHours();
	    ck.HHMM[0].v = Math.floor(hour / 10);   ck.HHMM[1].v = (hour % 10);
	    ck.HHMM[2].v = Math.floor(min / 10);    ck.HHMM[3].v = (min % 10);
	  }
	}
	ck.secPts[sec].on = true;  ck.timerTic = 0;  ck.secCour = sec;
      }
      ck.timerTic++;

      // debug
//      ck.HHMM[0].v = 8;  ck.HHMM[1].v = 8;  ck.HHMM[2].v = 8;  ck.HHMM[3].v = 8;
    },
  },
}

// --------------------------------------------------------------------------------

</script>

<style lang="scss" scoped>

.clock {
  margin: auto;
  background: transparent;
  override: hidden;
  z-index: 5;
  height: 100%;
  width: 100%;
}

</style>
