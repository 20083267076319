<template>

<div>
  <div class="dorrough">
    <svg :height="_canvasHeight" :width="_canvasWidth">

      <defs>
        <linearGradient id="captionHighlight">
          <stop offset="0%" stop-color="#333" />
          <stop offset="50%" :stop-color="captionHighlightColor" />
          <stop offset="100%" stop-color="#333" />
        </linearGradient>

        <linearGradient id="captionHighlightMid">
          <stop offset="0%" stop-color="transparent" />
          <stop offset="15%" stop-color="#fff" />
          <stop offset="85%" stop-color="#fff" />
          <stop offset="100%" stop-color="transparent" />
        </linearGradient>
      </defs>

        <!-- <linearGradient id="captionHighlightMid"> -->
        <!--   <stop offset="0%" stop-color="transparent" /> -->
        <!--   <stop offset="32%" stop-color="#444" /> -->
        <!--   <stop offset="68%" stop-color="#444" /> -->
        <!--   <stop offset="100%" stop-color="transparent" /> -->
        <!-- </linearGradient> -->

        <!-- <linearGradient id="captionHighlightMid"> -->
        <!--   <stop offset="0%" stop-color="transparent" /> -->
        <!--   <stop offset="32%" stop-color="#903a10" /> -->
        <!--   <stop offset="68%" stop-color="#903a10" /> -->
        <!--   <stop offset="100%" stop-color="transparent" /> -->
        <!-- </linearGradient> -->

      <!-- légende haut -->
      <path v-for="item,idx in legendArcPrms.haut" :key="idx + item.start.x"
            :d="'M' + item.start.x + ' ' + item.start.y + ' A' + legendRayon + ' ' + legendRayon + ' 0 '
                + item.largeArcFlag + ' 1 ' + item.end.x + ' ' + item.end.y "
            :stroke-width="legendWidth" :stroke="item.color" fill="none"
            :transform="'translate(' + posTrans.x + ',' + posTrans.y + '), rotate(' + _rotationAngl + ',' + gaugePos.x + ',' + gaugePos.y + ')'" />

      <!-- légende bas -->
      <path v-for="item,idx in legendArcPrms.bas" :key="idx + item.start.x"
            :d="'M' + item.start.x + ' ' + item.start.y + ' A' + (legendRayon - gaugeWidth - 2 * legend2GaugeSpace - 3) + ' '
                + (legendRayon - gaugeWidth - 2 * legend2GaugeSpace - 3) + ' 0 '
                + item.largeArcFlag + ' 1 ' + item.end.x + ' ' + item.end.y "
            :stroke-width="legendWidth" :stroke="item.color" fill="none"
            :transform="'translate(' + posTrans.x + ',' + posTrans.y + '), rotate(' + _rotationAngl + ',' + gaugePos.x + ',' + gaugePos.y + ')'" />

      <!-- db scale -->
      <text v-for="item,idx in scaleData" :key="idx" :x="(_canvasWidth / 2)" :y="10" font-family="Verdana" :font-size="9" :fill="captionColor"
            :transform="'rotate(' + (item.a * _apertureAngl) + ',' + (_canvasWidth / 2) + ',' + legendRayon + ')'">{{ item.t }}</text>

      <!-- gauge inactive -->
      <path :d="'M' + gaugeStart.x + ' ' + gaugeStart.y + ' A' + gaugeRayon + ' ' + gaugeRayon + ' 0 '
                + largeArcFlagBg + ' 1 ' + gaugeEnd.x + ' ' + gaugeEnd.y"
            :stroke-width="gaugeWidth" :stroke="gaugeBgColor" fill="none"
            :transform="'translate(' + posTrans.x + ',' + posTrans.y + '), rotate(' + _rotationAngl + ',' + gaugePos.x + ',' + gaugePos.y + ')'" />


      <!-- masque de la gauge active -->
      <clipPath id="gauge-mask-clip" clipPathUnits="userSpaceOnUse">
        <!-- level -->
        <rect :x="(gaugePos.x - gaugeRayon)" :y="(gaugePos.y - gaugeRayon + posTrans.y - gaugeWidth)" :width="gaugeRayon" :height="gaugeRayon + gaugeWidth"
              :transform="'rotate(' + (currAngl * apertureAngl + _rotationAngl) + ',' + (posTrans.x + gaugePos.x) + ',' + (posTrans.y + gaugePos.y) + ')'"
              style="transition: transform 0.1s;" />

        <!-- peak -->
        <rect :x="(gaugePos.x - gaugeHashSize * peakWidth)" :y="(gaugePos.y - gaugeRayon + posTrans.y - gaugeWidth)" :width="(gaugeHashSize * peakWidth)"
              :height="gaugeRayon + gaugeWidth"
              :transform="'rotate(' + (peakAngl * apertureAngl + _rotationAngl) + ',' + (posTrans.x + gaugePos.x) + ',' + (posTrans.y + gaugePos.y) + ')'"
              style="transition: transform 0.1s;" />
      </clipPath>

      <!-- gauge active -->
      <g clip-path="url(#gauge-mask-clip)">
        <path v-for="item,idx in gaugeArcPrms" :key="idx + item.start.x"
              :d="'M' + item.start.x + ' ' + item.start.y + ' A' + gaugeRayon + ' ' + gaugeRayon + ' 0 '
                  + item.largeArcFlag + ' 1 ' + item.end.x + ' ' + item.end.y "
              :stroke-width="gaugeWidth" :stroke="item.color" fill="none"
              :transform="'translate(' + posTrans.x + ',' + posTrans.y + '), rotate(' + _rotationAngl + ',' + gaugePos.x + ',' + gaugePos.y + ')'" />
      </g>

      <path v-if="gaugeHash" :d="'M' + gaugeStart.x + ' ' + gaugeStart.y
                + ' A' + gaugeRayon + ' ' + gaugeRayon + ' 0 ' + largeArcFlag + ' 1 '
                + gaugeEnd.x + ' ' + gaugeEnd.y "
            :stroke-width="gaugeWidth" :stroke-dasharray="gaugeHashSize" stroke="#000" fill="none"
            :transform="'translate(' + posTrans.x + ',' + posTrans.y + '), rotate(' + _rotationAngl + ',' + gaugePos.x + ',' + gaugePos.y + ')'" />

      <!-- db -->
      <text :x="(_canvasWidth / 2 + unitPosAdjust.x)" :y="unitPosAdjust.y" font-family="Verdana" :font-size="12" :letter-spacing="2"
            :fill="captionColor" text-anchor="middle" alignment-baseline="middle">db</text>

      <!-- caption -->
      <g v-if="captionHighlight">
        <rect :x="gaugeStart.x - 10" :y="(captionPos.y - captionFontSize - 5)"
              :height="1" :width="(gaugeEnd.x + 20 - gaugeStart.x)" fill="url(#captionHighlight)" />

        <rect :x="(gaugeStart.x - 10)" :y="(captionPos.y - captionFontSize - 2)" stroke-dasharray="2"
              :height="2 * captionFontSize - 5" :width="(gaugeEnd.x - gaugeStart.x + 20)"
              fill="url(#captionHighlightMid)" opacity="0.05" />

        <!-- <rect :x="(gaugeStart.x - 60)" :y="(captionPos.y - captionFontSize - 2)" stroke-dasharray="2" -->
        <!--       :height="2 * captionFontSize - 5" :width="(gaugeEnd.x - gaugeStart.x + 120)" -->
        <!--       fill="url(#captionHighlightMid)" opacity="0.5" /> -->

        <rect :x="gaugeStart.x - 10" :y="(captionPos.y + captionFontSize - 6)"
              :height="1" :width="(gaugeEnd.x + 20 - gaugeStart.x)" fill="url(#captionHighlight)" />
      </g>

      <text v-if="captionVisible" :x="captionPos.x" :y="captionPos.y" :font-size="_captionFontSize" :letter-spacing="3"
            :fill="(captionHighlight ? '#ccc' : captionColor)" text-anchor="middle" >{{ captionText }}</text>

    </svg>
  </div>

</div>

</template>


<script>

// --------------------------------------------------------------------------------

//import { gsap } from 'gsap';

export default {
  name: 'sDorrough',

  // Property types : String, Number, Boolean, Array, Object, Date, Function, Symbol
  props: {
    canvasWidth           : { type: Number, default: 100 },
    canvasHeight          : { type: Number, default: 100 },

    // Niveau de gauge courant, min, max
    levelMin              : { type: Number, default: 0 },
    levelMax              : { type: Number, default: 1000 },

    // Largeur & couleur gauge
    gaugeWidth            : { type: Number, default: 15 },  // 0 = desactivée
    gaugeBgColor          : { type: String, default: '#444444' },
    gaugeBaseColor        : { type: String, default: '#ff6e00' },
    gaugeColors           : { type: Array, default: function() { return []; } },  // [{ level: 0, color: '#fcc' }, { level: 40, color: '#5fc' }]
    gaugeHash             : { Type: Boolean, default: false },
    gaugeHashSize         : { Type: Number, default: 3 },
    peakWidth             : { Type: Number, default: 2 },

    // Ajustement position / rayon pour gauge & legende
    gaugePosAdjust        : { type: Object, default: function() { return({ x: 0, y: 15 }); } },
    gaugeRayAdjust        : { type: Number, default: 0 },

    // Largeur & couleur légende de gauge
    legendWidth           : { type: Number, default: 2 },
    legendBaseColor       : { type: String, default: '#ff6e00' },
    legendColors          : { type: Array, default: function() { return []; } },

    // Espacement entre légende et gauge
    legend2GaugeSpace     : { type: Number, default: 2 },

    // Units (db)
    unitPosAdjust         : { type: Object, default: function() { return({ x: 0, y: 65 }); } },

    // Caption
    captionVisible        : { type: Boolean, default: true },
    captionFontSize       : { type: Number, default: 12 },
    captionPosAdjust      : { type: Object, default: function() { return({ x: 0, y: 40 }); } },
    captionText           : { type: String, default: 'Meter' },
    captionColor          : { type: String, default: '#eee' },

    captionHighlightColor : { type: String, default: '#ff6e00' },
    captionHighlight      : { type: Boolean, default: false },

    apertureAngl          : { type: Number, default: 60 },
    rotationAngl          : { type: Number, default: 0 },
  },

  data: function() {
    return{
      legendArcPrms       : { haut: [], bas: [] },
      gaugeArcPrms        : [],
      scaleData           : [{ t: '-24', a: -0.56 }, { t: '-18', a: -0.36 }, { t: '-12', a: -0.145 }, { t: '-6', a: 0.093 },
                             { t: '0', a: 0.321 }, { t: '+6', a: 0.485 }, ],
      // scaleData           : [{ t: '-40', a: -0.57 }, { t: '-30', a: -0.38 }, { t: '-20', a: -0.169 }, { t: '-10', a: 0.046 },
      //                        { t: '0', a: 0.276 }, { t: '+12', a: 0.485 }, ],
      levelCur_           : 0,
      peakCur_            : 0,
    }
  },

  // watch: {
  //   levelCur     : function (val) {
  //     if (!this.initDone) { this.init(); }

  //     if (val > this._levelMax) { val = this._levelMax; }
  //     if (val < this._levelMin) { val = this._levelMin; }

  //     this.levelCur_ = val;
  //   },

  //   peakCur     : function (val) {
  //     if (!this.initDone) { this.init(); }

  //     if (val > this._levelMax) { val = this._levelMax; }
  //     if (val < this._levelMin) { val = this._levelMin; }

  //     this.peakCur_ = val;
  //   },
  // },

  mounted: function () {
    this.init ();
  },

  methods: {

    init : function() {
      this.legendArcPrms.haut = this.mkLegendArcPrms(this.legendRayon);
      this.legendArcPrms.bas = this.mkLegendArcPrms(this.legendRayon - this.gaugeWidth - 2 * this.legend2GaugeSpace - 3);
      this.gaugeArcPrms = this.mkGaugeArcPrms();
    },

    update : function (rms, peak) {

      if (rms > this._levelMax) { rms = this._levelMax; }
      if (rms < this._levelMin) { rms = this._levelMin; }

      if (peak > this._levelMax) { peak = this._levelMax; }
      if (peak < this._levelMin) { peak = this._levelMin; }

      this.levelCur_ = rms;
      this.peakCur_ = peak;
    },

    // Calcul angle en fonction du niveau de gauge (level)
    level2Angl : function(level) { return(this.startAngl - (level - this._levelMin) / (this._levelMax - this._levelMin) * 2 * this.startAngl); },

    // Position (x,y) d'un point de la legende en fonction du niveau avant translation/rotation
    level2Pos : function(level, ray) {
      let point = { x: 0, y: 0 };

      let angle = this.level2Angl(level);
      point.x = this.gaugePos.x + (ray * Math.sin(angle));
      point.y = this.gaugePos.y - (ray * Math.cos(angle));
      return(point);
    },

    // Legende : calcul positions début/fin et couleurs des arcs à tracer
    mkLegendArcPrms : function(ray) {

      let arcPrms = [];

      // Legende désactivée
      if (this._legendWidth == 0) { return(arcPrms); }

      // Valeurs par défaut si couleurs personnalisées non spécifiées
      if (this.legendColors.length == 0) {

        let start = this.level2Pos(this._levelMin, ray);
        let end = this.level2Pos(this._levelMax, ray);

        arcPrms = [{ start : start,
                     end   : end,
                     largeArcFlag : this.largeArcFlag,
                     color : this.legendBaseColor }];
        return(arcPrms);
      }

      // Paramètres de tous les arcs à tracer (position deb et fin, couleur)
      let level = this._levelMin;

      for (let item of this.legendColors) {

        let start = this.level2Pos(level, ray);
        let end = this.level2Pos(item.level, ray);

        arcPrms.push({ start : start,
                       end   : end,
                       largeArcFlag : (this.level2Angl(item.level) - this.level2Angl(level) >= Math.PI) ? 1 : 0,
                       color : item.color });
        level = item.level;
      }
      return(arcPrms);
    },

    // Legende : calcul positions début/fin et couleurs des arcs à tracer
    mkGaugeArcPrms : function() {

      let arcPrms = [];

      // Valeurs par défaut si couleurs personnalisées non spécifiées
      if (this.gaugeColors.length == 0) {

        let start = this.level2Pos(this._levelMin, this.gaugeRayon);
        let end = this.level2Pos(this._levelMax, this.gaugeRayon);

        arcPrms = [{ start : start,
                     end   : end,
                     largeArcFlag : this.largeArcFlag,
                     color : this.gaugeBaseColor }];
        return(arcPrms);
      }

      // Paramètres de tous les arcs à tracer (position deb et fin, couleur)
      let level = this._levelMin;

      for (let item of this.gaugeColors) {

        let start = this.level2Pos(level, this.gaugeRayon);
        let end = this.level2Pos(item.level, this.gaugeRayon);

        arcPrms.push({ start : start,
                       end   : end,
                       largeArcFlag : (this.level2Angl(item.level) - this.level2Angl(level) >= Math.PI) ? 1 : 0,
                       color : item.color });
        level = item.level;
      }
      return(arcPrms);
    },

  },

  computed: {

    // --------------------------------------------------------------------------------
    // Proprietes composant
    //
    _canvasWidth : function() { return(Math.max(this.canvasWidth, 10)); },
    _canvasHeight: function() { return(Math.max(this.canvasHeight, 10)); },

    _levelMax    : function() { return(Math.max(this.levelMax, this.levelMin)); },
    _levelMin    : function() { return(Math.min(this.levelMax, this.levelMin)); },

    _captionFontSize : function() { return(Math.max(this.captionFontSize, 5)); },

    _apertureAngl : function() {
      if (this.apertureAngl < 30) { return(30); }
      if (this.apertureAngl >= 360) { return(359.99); }
      return (this.apertureAngl)
    },
    _rotationAngl : function() {
      if (this.rotationAngl < 0) { return(0); }
      if (this.rotationAngl > 180) { return(180); }
      return(this.rotationAngl);
    },

    _legendWidth : function() { return(Math.max(0, this.legendWidth)); },
    _legend2GaugeSpace : function() { return(Math.max(0, this.legend2GaugeSpace)); },
    _gaugeWidth : function() { return(Math.max(0, this.gaugeWidth)); },


    // --------------------------------------------------------------------------------
    // Valeurs internes calculées
    //

    // Calcul du plus grand rayon possible pour garder la gauge dans le canvas
    legendCanvas: function() { return(this.gaugeRayAdjust + Math.max(this._canvasWidth, this._canvasHeight)); },
    legendRayon : function() { return((this.legendCanvas - this._legendWidth) / 2); },
    gaugeRayon  : function() { return(this.legendRayon - this._legendWidth - this._legend2GaugeSpace - (this._gaugeWidth / 2)); },

    // Point central, position gauge et caption
    centerPos   : function() { return({ x: this._canvasWidth / 2, y: this._canvasHeight / 2 }); },
    gaugePos    : function() { return({ x: this.centerPos.x + this.gaugePosAdjust.x, y: this.centerPos.y + this.gaugePosAdjust.y }); },
    captionPos  : function() { return({ x: this.centerPos.x + this.captionPosAdjust.x, y: this.centerPos.y + this.captionPosAdjust.y }); },

    // Angle de départ et angle correspondant eu niveau courant
    startAngl   : function() { return(- (this._apertureAngl / 2) * (Math.PI / 180)); },
    currAngl    : function() { return(this.startAngl - (this.levelCur_ - this._levelMin) / (this._levelMax - this._levelMin) * 2 * this.startAngl); },
    peakAngl    : function() { return(this.startAngl - (this.peakCur_ - this._levelMin) / (this._levelMax - this._levelMin) * 2 * this.startAngl); },

    // Point de départ arc de cercle gauge (optimisation pour éviter appel inutile à level2Pos lors du refresh de la gauge)
    gaugeStart : function() {
      let point = { x: 0,  y: 0 };

      point.x = this.gaugePos.x + (this.gaugeRayon * Math.sin(this.startAngl));
      point.y = this.gaugePos.y - (this.gaugeRayon * Math.cos(this.startAngl));
      return(point);
    },

    // Point d'arrivée arc de cercle gauge (optimisation pour éviter appel inutile à level2Pos lors du refresh de la gauge)
    gaugeEnd : function() {
      let point = { x: 0,  y: 0 };

      point.x = this.gaugePos.x - (this.gaugeRayon * Math.sin(this.startAngl));
      point.y = this.gaugePos.y - (this.gaugeRayon * Math.cos(this.startAngl));
      return(point);
    },

    // Position courante arc de cercle gauge
    gaugeCurr : function() { return(this.level2Pos(this.levelCur_, this.gaugeRayon)); },

    // Paramètre large-arc-flag de la fonction arc (cf. path svg)
    largeArcFlag : function() { return((this._apertureAngl >= 180) ? 1 : 0); },
    largeArcFlagBg : function() { return((-this.startAngl - this.currAngl  >= Math.PI) ? 1 : 0); },
//    largeArcFlagCurr : function() { return((this.currAngl - this.startAngl >= Math.PI) ? 1 : 0); },

    // Translation de la gauge pour qu'elle apparaisse dans le canvas (cas rayon grand & angle ouverture resséré)
    posTrans : function() {
      let trans = { x: 0,  y: 0 };
      let ray = this.legendRayon + this.legendWidth / 2;

      // Si rayon plus grand que canvasHeight / 2, translation en y
      if (ray > this._canvasHeight / 2) {
        trans.y = Math.cos(this._rotationAngl * (Math.PI / 180)) * (this.legendRayon + this.legendWidth / 2 - (this._canvasHeight / 2));
      }

      // Si rayon plus grand que canvasWidth / 2, translation en x
      if (ray > this._canvasWidth / 2) {
        trans.x = Math.sin(this._rotationAngl * (Math.PI / 180)) * (- this.legendRayon - this.legendWidth / 2 + (this._canvasWidth / 2));
      }

/*      if (this._canvasHeight > this._canvasWidth) { trans.x = - (this._canvasHeight - this._canvasWidth) / 2; }
      if (this._canvasHeight < this._canvasWidth) { trans.y = (this._canvasWidth - this._canvasHeight) / 2; }*/
      return(trans);
    },
  },
}

// --------------------------------------------------------------------------------

</script>


<style lang="scss" scoped>

.dorrough {
  display: block;
  float: left;
/*  transform: rotate(-0.25turn);*/
  background-color: transparent;
}


/* Enter and leave animations can use different durations and timing functions */
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
/*  transform: translateX(10px);*/
  opacity: 0;
}

</style>
