<template>
<div id="app" :style="'opacity:' + opacity">

  <meta name="description" content="Pistache la radio">
  <meta name="keywords" content="radio webradio pistache la radio">
  <meta http-equiv="Content-Language" content="fr">
  <meta charset="utf-8">

  <!-- ajustement ratio affichage pour iOS -->
  <meta name="viewport" content="width=device-width, initial-scale=0.65">

  <div class="bandeau" />


  <!-- damier fond -->
  <div class="fondDamier" v-if="false">
    <div style="width: 3660px; height: 811px;">
      <svg :viewBox="'0 0 2000 820'" style="margin-top: 300px; z-index: 0">

        <circle v-for="item,idx of bgLines" :key="'circles' + idx"
                :cx="item.x" :cy="item.y" :r="item.r" fill="#444" transform="scale(1 0.35)" :opacity="item.o" />
      </svg>
    </div>
  </div>


  <div class="main" v-if="true">

    <div class="corps" ref="corps">

      <div class="title">
        <font-awesome-icon class="ico" icon="play" />
        Pistache - La Radio
        <!-- www.pistache.org -->
      </div>

      <div class="menu">
        <div class="line-center" style="justify-content: flex-start;">
          <!-- <div class="button1" style="margin-right: 20px;"><font-awesome-icon class="fa-ico" icon="home" style="font-size: 17px" /></div> -->
          <!-- <div class="button1" style="margin-right: 20px;">Rechercher</div> -->
        </div>
      </div>

      <!-- damier fond -->
      <!-- <div v-if="displayMode == 2" class="fondDamier"> -->
      <!--   <div style="width: 3550px; height: 811px;"> -->
      <!--     <svg :viewBox="'0 0 2000 820'" style="margin-top: 300px; z-index: 0"> -->

      <!--       <circle v-for="item,idx of bgLines" :key="'circles' + idx" -->
      <!--               :cx="item.x" :cy="item.y" :r="item.r" fill="#600" transform="scale(1 0.35)" :opacity="item.o" /> -->
      <!--     </svg> -->
      <!--   </div> -->
      <!-- </div> -->

      <lastDiff :lastDiff="lastDiff" :style="'transition: height ease-in-out 0.5s;' + mkCssPos('lastDiff')" />
      <onAir :options="options" :displayMode="displayMode" :lastDiff="lastDiff"
             :style="mkCssPos('onAir') + (onAirOpacity ? 'transition: opacity ease-in 0.5s;' : '') + 'opacity: ' + onAirOpacity + '; z-index: 2;'"
             @toggleOption="toggleOption($event)" />

      <sPlayer :displayMode="displayMode" :options="options" @toggleOption="toggleOption($event)" ref="player"
               :style="'z-index: 1;' + mkCssPos('player') + (displayMode == 1 ? 'display: none' : '')"
               @setOption="function (opt) { options[opt.name] = opt.val; }" />

      <!-- clock / pistache -->
      <div class="col-top" :style="'z-index: 1;' + mkCssPos('pistache')">
        <clock style="height: 225px; width: 225px; z-index: 1; transform: translate(12px, 239px)" />
        <img alt="Pistache" src="./assets/pistache-1.png" height="13">
        <img alt="Pistache" src="./assets/pistache-2.png" height="460">
        <img alt="Pistache" src="./assets/pistache-3.png" height="343">

        <!-- <div v-if="displayMode == 2" class="btnBarLines" /> -->

        <div class="line-center" :style="mkCssPos('btnBar') + 'padding: 0px 15px 0px 15px;'">
          <el-tooltip effect="dark" content="play (web player)" :hide-after="2000">
            <div class="sbutton circle" @click="$refs.player.playerToggle()" style="color: #bbb;">
              <font-awesome-icon class="ico" :icon="($refs.player && !$refs.player.playing() ? 'play' : 'pause')" />
            </div>
          </el-tooltip>
          <el-tooltip effect="dark" content="mp3 (external player)" :hide-after="2000">
            <a :href="prms.sourceURL.mp3" target="_blank">
              <div class="sbutton circle" style="color: #ddd;">mp3</div>
            </a>
          </el-tooltip>
          <el-tooltip effect="dark" content="aac (external player)" :hide-after="2000">
            <a :href="prms.sourceURL.aac" target="_blank">
              <div class="sbutton circle" style="color: #ddd;">aac</div>
            </a>
          </el-tooltip>
        </div>

        <!-- <div v-if="displayMode == 2" class="btnBarLines" style="margin-top: 649px;" /> -->

        <!-- <div v-if="userAgent" style="font-size: 12px; width: 240px;"> -->
        <!--   <\!-- mobile: {{ userAgent.mobile }} <br /> -\-> -->
        <!--   <\!-- platform: {{ userAgent.platform }}<br /> -\-> -->
        <!--   userAgentData: {{ userAgent }} <br /> -->
        <!-- </div> -->

      </div>

    </div>
  </div>

  <div class="footer">
    Pistache La Radio : tous les hits sans pub, des années 80 à aujourd'hui.<br>
    www.pistache.org - 09/2006, 01/2009, 02/2022 - suggestion, commentaire ou question : contact(@)pistache.org
  </div>

  <!-- <div class="tooltip" :style="'left:' + tooltip.posX + 'px; top:' + tooltip.posY + 'px; opacity:' + (tooltip.visible ? 1 : 0)"> -->
  <!--   {{ tooltip.msg }} -->
  <!-- </div> -->

</div>
</template>

<script>
import clock from './components/clock.vue'
import sPlayer from './components/splayer.vue'
import lastDiff from './components/lastDiff.vue'
import onAir from './components/onAir.vue'
import { prms } from './pistache-cfg.js'

// code service websocket
const codeServiceWS = 'lkvZ12gdL';


export default {
  name: 'App',
  components: {
    clock, sPlayer, lastDiff, onAir,
  },
  //   metaInfo: {
  //     meta: [
  //       { charset: 'utf-8' },
  // //      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
  //       { name: 'viewport', content: 'initial-scale=0.5, maximum-scale=0.5, user-scalable=0' },
  //     ],
  //   },

  data: function () {
    return ({

      prms              : { web: '', mp3: '', aac: '' },
      displayMode       : 2,
      opacity           : 0,
      onAirOpacity      : 1,
//      tooltip           : { msg: '', posX: 0, posY: 0, visible: false, timer: null },
      mousePos          : { x: 0, y: 0 },

      pos               : {
        pistache : { left: 0, top: -305, width: 249, height: 1200 },
        player   : { left: 0, top: 0, width: 0, height: 0 },
        lastDiff : { left: 0, top: 30, width: 0, height: 0 },
        onAir    : { left: 0, top: 0, width: 0, height: 0 },
        btnBar   : { left: 20, top: 0, width: 205, height: 60 },
      },

      options           : {
        mute     : false,
        equalizer: true,
        audio    : false,
        meters   : false,
        spectrum : false,
        base     : false,
      },

      userAgent  : null,
      saveTimer  : null,

      lastDiff          : [],
      lastDiffTimer     : null,
    });
  },

  sockets: {

    // connexion au serveur websocket établie
    connect: function () {
      console.log ('connected to server');
      //      this.connected = true;

      // envoi code d'identification
      this.$socket.emit ('login', codeServiceWS);
      this.$socket.emit ('getstatus');
    },

    // réception de l'état courant
    status: function (data) {

      let that = this;

      // effacement des informations de dernière diffusion si aucune donnée recue depuis plus de 15 minutes
      if (this.lastDiffTimer) { clearTimeout (this.lastDiffTimer); }
      this.lastDiffTimer = setTimeout (function () { that.lastDiff = []; }, 1000 * 60 * 15);

      this.lastDiff = data;
      ////      console.log (data);
    },

    // connexion au serveur websocket terminée
    disconnect: function () {
      console.log ('disconnected from server');
      this.connected = false;
    },
  },

  computed: {

    // bgLines: function () {

    //   const width = 900;
    //   const height = 800;
    //   const nbLineY = 35;
    //   const nbLinesX = 30;

    //   const yScaleRatio = 0.988;
    //   const xScaleRatio = 0.98;
    //   const rScaleRatio = 0.98;
    //   const oScaleRatio = 0.03;

    //   let rayon = 10;
    //   let spaceX = 29;
    //   let spaceY = 28;
    //   let opacity = 1;

    //   let result = [];
    //   for (let y = 0;  y < nbLineY;  y++) {
    //     for (let x = 0;  x < nbLinesX;  x++) {
    //       result.push ({ x: width / 2 + x * spaceX, y: height - y * spaceY, r: rayon, o: opacity });
    //       if (x != 0) {
    //         result.push ({ x: width / 2 - x * spaceX, y: height - y * spaceY, r: rayon, o: opacity });
    //       }
    //     }
    //     spaceX *= xScaleRatio;  spaceY *= yScaleRatio;  rayon *= rScaleRatio;
    //     opacity = Math.max (0, opacity - oScaleRatio);
    //   }
    //   return (result);
    // },
  },

  methods: {

    toggleOption: function (opt) {
      let that = this;

      if (opt != '') { this.options[opt] = !this.options[opt]; }

      this.options.meters = this.options.meters && this.options.audio;
      this.options.equalizer = this.options.equalizer && this.options.audio && this.displayMode == 2;

      if (opt == 'equalizer' && this.displayMode == 2) { this.onAirOpacity = 0;  setTimeout (function () { that.onAirOpacity = 1; }, 200); }
      if (opt == 'mute') { this.$refs.player.gainUpdate (); }

      this.onResize ();
      this.saveOptions ();
    },

    onResize: function () {

      let result = 2;

      // mode étroit si portrait ou largeur de viewport < 1170
      if (window.orientation == 0 || window.orientation == 180) { result = 1; }
      if (window.innerWidth <= 1170) { result = 1; }

      this.displayMode = result;

      // taille corps
      const cx = this.$refs.corps.clientWidth;
      const cy = this.$refs.corps.clientHeight;

      // positionnements - mode large
      const x = this.pos;

      // mode paysage
      if (this.displayMode == 2) {

        // pistache
        x.pistache.top = -305;
        x.pistache.left = (cx - x.pistache.width);
        x.pistache.height = 1200;

        // width
        x.player.width = 400 + (cx / 5.5);
        x.lastDiff.width = 200 + (cx / 8);
        x.onAir.width =  (this.options.equalizer ? 200 + (cx / 8) : x.player.width);

        // marges restantes
        const marges = (cx - x.player.width - x.lastDiff.width) - 260;

        // left
        x.lastDiff.left = Math.max (0, marges * 0.42 - 5);
        x.player.left = x.lastDiff.left + x.lastDiff.width + Math.max (0, marges * 0.26 + 5);
        x.onAir.left = (this.options.equalizer ? x.lastDiff.left : x.player.left);

        // height
        x.player.height = (this.options.equalizer ? 662 : 262);
        x.lastDiff.height = (this.options.equalizer ? 380: 662);
        x.onAir.height = (this.options.equalizer ? 262 : 380);

        // top
        x.player.top = 30;
        x.onAir.top = (this.options.equalizer ? 430 : 30);

        // btnBar
        x.btnBar.top = 585;
      }

      // mode mobile
      // REM : pb mediaElement sous ios => désactivation complète du player en mode mobile / low res :(
      if (this.displayMode == 1) {
        this.options.equalizer  = false;

        // width
        x.player.width = 490 + (cx / 5.5);
        x.lastDiff.width = 240 + (cx / 6.5);
        x.onAir.width = x.player.width;

        // left
        x.lastDiff.left = (cx - x.player.width) / 2;
        x.onAir.left = x.lastDiff.left;
        x.player.left = x.lastDiff.left;

        // height
        x.lastDiff.height = 650;
        x.onAir.height = 480;
        x.player.height = 262;

        // top
        x.player.top = x.lastDiff.top + x.lastDiff.height + x.onAir.height + 20;
        x.onAir.top = x.lastDiff.top + x.lastDiff.height + 20;

        // pistache
        x.pistache.top = -195;
        x.pistache.height = 875;
        x.pistache.left = x.lastDiff.left + x.player.width - x.pistache.width;

        // btnBar
        x.btnBar.top = 510;
      }

      // if (this.displayMode == 1) {

      //   // width
      //   x.player.width = 490 + (cx / 5.5);
      //   x.lastDiff.width = 240 + (cx / 6.5);
      //   x.onAir.width = (this.options.equalizer ? 240 + (cx / 6.5) : x.player.width);

      //   // left
      //   x.lastDiff.left = (cx - x.player.width) / 2;
      //   x.onAir.left = x.lastDiff.left;
      //   x.player.left = x.lastDiff.left;

      //   // height
      //   x.lastDiff.height = (this.options.equalizer ? 386 : 650);
      //   x.onAir.height = (this.options.equalizer ? 264 : 380);
      //   x.player.height = (this.options.equalizer ? 662 : 262);

      //   // top
      //   x.player.top = x.lastDiff.top + x.lastDiff.height + x.onAir.height + 20 + (this.options.equalizer ? 0 : 20);
      //   x.onAir.top = x.lastDiff.top + x.lastDiff.height + (this.options.equalizer ? 0 : 20);

      //   // pistache
      //   x.pistache.top = -196;
      //   x.pistache.height = 877;
      //   x.pistache.left = x.lastDiff.left + x.player.width - x.pistache.width;
      // }
    },

    mkCssPos: function (item) {

      let result = 'position: absolute;';
      result += 'margin-top: ' + this.pos[item].top + 'px; margin-left: ' + this.pos[item].left + 'px;';
      result += 'width: ' + this.pos[item].width + 'px; height: ' + this.pos[item].height +'px;';

      return (result);
    },

    // chargement des options d'affichage depuis stockage local du navigateur
    loadOptions: function () {

      try {
        const opts = JSON.parse (localStorage.pistache_disp);
        if (!opts) { return; }

        for (let opt in this.options) {
          if (opts[opt] != undefined && typeof(opts[opt]) == 'boolean') { this.options[opt] = opts[opt]; }
        }
      }
      catch (e) { localStorage.clear (); }
    },

    // sauvegarde des options d'affichage dans stockage local du navigateur
    saveOptions: function () {

      if (this.saveTimer) { clearTimeout (this.saveTimer); }
      let that = this;

      this.saveTimer = setTimeout (function () {
        that.saveTimer = null;
        localStorage.pistache_disp = JSON.stringify (that.options);
      }, 1000);
    },

    // tooltipShow: function (msg, duration = 2000) {

    //   const x = this.tooltip;

    //   x.msg = msg;
    //   x.posX = this.mousePos.x;  x.posY = this.mousePos.y;
    //   x.visible = true;

    //   if (x.timer) { clearTimeout (x.timer); }
    //   x.timer = setTimeout (function () { x.visible = false;  x.timer = null; }, duration);
    // },

    // trackMousePosition: function (event) {

    //   this.mousePos = { x: event.clientX, y: event.clientY };
    // },
  },

  created: function () {
    this.prms = prms;
  },

  mounted: function () {

    const that = this;

    this.opacity = 1;
    this.loadOptions ();

    this.userAgent = navigator.userAgentData || navigator.userAgent || navigator.vendor || window.opera;
    if (!this.userAgent) { this.userAgent = { mobile: true, platform: 'unknown' }; }

    window.addEventListener ('resize', this.onResize);
    this.onResize ();
    this.toggleOption ('');

//    this.$eventBus.$on ('tooltipShow', function (msg, duration) { that.tooltipShow (msg, duration); });
  },
}
</script>

<!-------------------------------------------------------------------------------->

<style lang="scss">
@import "./sb-theme.scss";
@import "./style.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-width: 600px;
  height: 880px;

  @media screen and (min-width: 1900px) {
    width: 90.9%;
    transform: scale(1.1);
    transform-origin: top left;
  }

  @media screen and (min-width: 2200px) {
    width: 83.3%;
    transform: scale(1.2);
    transform-origin: top left;
  }

  @media screen and (min-width: 2500px) {
    width: 80%;
    transform: scale(1.25);
    transform-origin: top left;
  }

  @media screen and (max-width: 1500px) and (min-width: 1170px) {
    width: 111.1%;
    transform: scale(0.9);
    transform-origin: top left;
  }

  /* @media screen and (max-width: 1500px) { */
  /*   width: 111.1%; */
  /*   transform: scale(0.9); */
  /*   transform-origin: top left; */
  /* } */

  /* @media screen and (max-width: 1170px), screen and (orientation: portrait) { height: 1440px; } */

/*  filter: brightness(1.15) contrast(1.02); */
  filter: brightness(1.1);
  opacity: 0;
  transition: opacity ease-in-out 0.5s;
}

body {
  background-color: #000;
  /* color: #eee !important; */
  margin: 0;
  padding: 0;
}

.bandeau {
  /* background: linear-gradient(#cc4400 0%, #8d2f00 60%, #8d2f00 70%, #a73800 100%); */
  /* background: linear-gradient(#dd5500 0%, #8f3200 55%, #953500 72%, #b04500 100%); */
  /* background: linear-gradient(#f56c00 -20%, #8f3200 55%, #953500 72%, #f56c00 140%); */
  /* background: linear-gradient(#e80 -20%, #8f3200 55%, #953500 72%, #e80 140%); */
  background: linear-gradient(#d70 -20%, #8a3000 55%, #8a2a00 72%, #d70 140%);
  height: 180px;

  @media screen and (max-width: 1350px) {
    height: 130px;
    background: linear-gradient(#e80 -15%, #8f3200 38%, #953500 50%, #e80 140%);
  }
}

.fondDamier {
  width: 100%;
  height: 788px;
  overflow: hidden;
  margin-bottom: -788px;
}

.main {
  background: linear-gradient(#010101 0%, #191919 10%);

  /* margin-left: max(10px, calc(7vw + max(0px, calc(14vw - 4000px)))); */

  margin-left: max(10px, 6vw);
  margin-right: max(20px, 4.2vw);
  max-width: 1830px;

  @media screen and (max-width: 1350px) {
    margin-left: 0px;
    margin-right: 0px;
  }

  &>.corps {
    margin-left: auto;
    margin-right: auto;
    height: 848px;

    background: #0a0a0a;
    background-image: url('./assets/damier.png');
    background-size: 1820px 479px;
    background-repeat: no-repeat;
    background-position: 0px 374px;

    /* @media screen and (max-width: 1170px), screen and (orientation: portrait) { */
    @media screen and (max-width: 1170px) {
      height: 1270px;
      background-size: 2520px 910px;
      background-position: -450px 374px;
    }

    /* background-image: url('./assets/fond_brosse2.png'); */

    /* background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIGZpbGw9IiNmMDAiIC8+PC9zdmc+'); */

    /* background-image: url('data:image/svg+xml;charset=UTF-8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><rect width="50" height="50" fill="#f00" /></svg>'); */
    /* background-repeat: no-repeat; */
    /* background-size: cover; */

    /* background: linear-gradient(#111 0%, #252525 100%); */

    &>.title {
      margin-top: -50px;

      transform: translate(1px, -24px);

      @media screen and (max-width: 1350px) {
        transform: translate(3px, -30px);
      }

      width: 300px;
      height: 0px;

      color: #eee;

      font-family: arial;
      font-stretch: 100%;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 4px;
      line-height: 24px;
      /* vertical-align: baseline; */

      &>.ico {
        font-size: 13px;
        color: #ddd;
        margin-bottom: 1px;
        margin-right: 4px;
        /* @-moz-document url-prefix() { margin-bottom: -2px; } */
      }
    }

    &>.menu {
      width: 100%;
      height: 55px;
      line-height: 53px;
      font-size: 16px;
      font-family: arial;
      font-weight: 700;
      padding-left: 25px;
      background: linear-gradient(#888 0%, #333 45%, #222 55%, #000 100%);
      color: #eee;
    }
  }
}

/* .btnBarLines { */
/*   position: absolute; */
/*   height: 1px; */
/*   width: 240px; */
/*   margin-left: 4px; */
/*   margin-top: 580px; */
/*   background: linear-gradient(90deg, #222 0%, #f60 20%, #f60 80%, #222 100%); */
/*   opacity: 0.7; */
/* } */

.footer {
  background: linear-gradient(#f70 0%, #333 6px, #1a1a1a 80%, #000 100%);
  height: 100px;
  font-size: 11px;

  /* font-style: italic; */
  color: #888;
  text-shadow: 0px 0px 5px #000;

  user-select: text;
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text;   /* Safari */
  -khtml-user-select: text;    /* Konqueror HTML */
  -moz-user-select: text;      /* Firefox */
  -o-user-select: text;
  -ms-user-select: text;       /* Internet Explorer/Edge */

  padding: 7px 0px 0px max(10px, 6vw);

  @media screen and (max-width: 1350px) { padding-left: 3px; padding-right: 3px; }
}

/* .button1 { */
/*   /\* background: rgba(200, 40, 0, 0.6); *\/ */
/*   width: 80px; */
/*   height: 30px; */
/* } */

.el-slider__marks-text {
  font-size: 11px !important;
  color: #eee !important;
}

.box {
  margin-bottom: 20px;

  justify-content: flex-start;

  border-radius-bottom-left: 5px;
  border-radius-bottom-right: 5px;

  /* background: linear-gradient(#f56c00 0%, #000 16px, #151515 70%, #282828 calc(100% - 8px), #444 100%); */

  /* background: linear-gradient(#f70 0%, #000 16px, #1a1a1a 70%, #252525 calc(100% - 8px), #444 100%); */
  background: linear-gradient(#f70 0%, #a50 3px, #000 16px, #1a1a1a 70%, #252525 calc(100% - 8px), #444 100%);

  /* background: linear-gradient(#f70 0%, #111 16px, rgba(0, 0, 0, 0.3) 100%); */

  /* background-image: linear-gradient(#f70 0%, rgba(0, 0, 0, 0.9) 16px, rgba(0, 0, 0, 0.65) calc(100% - 8px), rgba(0, 0, 0, 0.4) 100%), url("./assets/fond_brosse.png"); */
  /* background-image: linear-gradient(#f70 0%,  rgba(0, 0, 0, 0.3) 8px, rgba(0, 0, 0, 0.3) calc(100% - 8px), rgba(0, 0, 0, 0) 100%), url("./assets/fond_brosse2.png"); */

  overflow: hidden;
  box-shadow: 0px 0px 2px 0px rgba(255, 255, 255, 0.3), 0px 0px 20px 5px rgba(0, 0, 0, 0.8);

  &.splayer {
    justify-content: space-around;
    height: 180px;
  }

  &.small {
    box-shadow: 0px 0px 2px 0px rgba(255, 255, 255, 0.3), 0px 0px 20px 5px rgba(0, 0, 0, 0.8);

  /* background: linear-gradient(#f70 0%, #000 7px, #1a1a1a 50%, #252525 calc(100% - 7px), #444 100%); */

    background: linear-gradient(#f70 0%, #a50 1px, #000 7px, #1a1a1a 50%, #252525 calc(100% - 7px), #444 100%);
    /* background: linear-gradient(#444 0%, #252525 4px, #000 7px, #1a1a1a 50%, #252525 calc(100% - 7px), #444 100%); */

    /* background-image: linear-gradient(#f70 0%,  rgba(0, 0, 0, 0.7) 8px, rgba(0, 0, 0, 0.65) calc(100% - 8px), rgba(0, 0, 0, 0.4) 100%), url("./assets/fond_brosse.png"); */
    /* background-image: linear-gradient(#f70 0%,  rgba(0, 0, 0, 0.2) 8px, rgba(0, 0, 0, 0.1) calc(100% - 8px), rgba(0, 0, 0, 0) 100%), url("./assets/fond_brosse2.png"); */
  }

  transition: opacity ease-in-out 0.5s;
}

/* bandeau de notification box */
.notify {
  width: 100%;
  position: absolute;
  z-index: 100;
  margin-top: 13px;

  /* background: #202020; */
  background: linear-gradient(#222 0%, #111 100%);
  border-bottom: 2px solid #f70;

  text-align: center;
  font-size: 15px;
  color: #eee;

  padding-top: 10px;
  padding-bottom: 12px;
  opacity: 0;

  &.on {
    opacity: 0.8;
  }

  transition: opacity ease-in-out 0.4s;
}


</style>
