
<template>
<div>

  <!-- <audio crossOrigin="false" controls="true" src="https://rpstrm.pistache.org:9443/pistache.mp3" /> -->

  <!-- spectrum -->
  <!-- <div class="line-center box" style="justify-content: flex-start;"> -->
    <!--   <canvas ref="spectrum" width="610" height="160" style="margin-top: 10px;" /> -->
    <!-- </div> -->

  <!-- curve EQ -->
  <div class="line-center box splayer" ref="curveBox" :style="'justify-content: flex-start; opacity: ' + (options.equalizer ? 1 : 0 + ';')">
    <canvas ref="curveEq" :width="boxWidth - 10 - (boxWidth - 610) * 0.2" height="160" style="margin-top: 3px; margin-left: 1%" />
  </div>

  <!-- equalizer -->
  <div class="line-center box splayer" :style="'opacity: ' + (options.equalizer ? 1 : 0 + ';')">

    <div />

    <!-- eq sliders -->
    <div v-for="item,idx in ['bass', 'warmth', 'med', 'presence', 'highs']" :key="item + idx"
         style="width: max(75px, 10%); padding: 19px 20px 10px 25px;" >

      <div class="col-top" style="width: 50px;">
        <el-slider vertical v-model="sliderEq[idx]" @change="eqFilterUpdate(idx)" :min="-12" :max="12"
                   :format-tooltip="function(val) { eqFilterUpdate(idx);  return (val + 'db'); }"
                   :marks="{ '-12': '', '-6': '', 0: '', 6: '', 12: '' }" :step="1"
                   style="height: 120px; width: 20px;" />

        <div style="width:80px; margin-top: 3px; margin-left:-20px; font-size:13px; color: #eee; text-align:center;">{{ item }}</div>
      </div>
    </div>

    <!-- db scale -->
    <div class="col-top" style="width: max(400px, 63%); margin-left: calc(20px + min(-400px, -67%); height: 120px;
                                margin-top: -9px; justify-content: space-between;">
      <div v-for="item,idx in { 30: '+12db', 60: '+6db', 90: '0db', 120: '-6db', 150:'-12db' }"
           style="height: 0; width: 100%; border-top: 1px dashed #666;"
           :key="'eqline' + idx">
        <div style="margin-left: calc(100% + 20px); margin-top: -6px; color: #eee; font-size: 10px;">{{ item }}</div>
      </div>
    </div>

    <!-- eq presets -->
    <div class="col-top" style="margin-top: 12px; margin-left: 75px; width: max(15%, 90px);">
      <div class="sbutton small rounded" v-for="ico,item in prms.eqPresets" :key="item"
           @click="eqButtonClick(item)" style="margin-bottom: 10px;">
        {{ item }}
      </div>
    </div>
  </div>

  <!-- meters -->
  <div class="line-center box splayer" :style="'padding: 30px 0px 0px 5px;' + (!options.meters && displayMode == 1 ? 'display: none;' : '')">
    <div />

    <div v-for="item,idx of prms.metersChannels" :key="idx" :style="'margin-top: -20px; transition: opacity ease-in-out 0.5s;' + (options.meters ? '' : 'opacity: 0.6') ">
      <sDorrough :levelMin="-25" :levelMax="6"
                 gaugeBgColor="#444" gaugeBaseColor="#f70" :gaugeWidth="18" :gaugeRayAdjust="220"
                 legendBaseColor="#f70" :legendWidth="2" :gaugeHash="true" :gaugeHashSize="2" :canvasWidth="297" :canvasHeight="130"
                 :captionText="item" :captionPosAdjust="{ x:0, y: 55 }" :unitPosAdjust="{ x:0, y: 73 }" :captionHighlight="true"
                 captionHighlightColor="#f80"

                 :legendColors="[{ level: 0, color: '#f70' }, { level: 0.2, color: '#000000' },
                                { level: 6, color: '#ff4000' } ]"
                 :gaugeColors="[{ level: 0, color: '#f70' }, { level: 12, color: '#ff4000' } ]"
                 ref="meter" />
    </div>
    <div />
  </div>

  <!-- level / buttons -->
  <div class="line-center box splayer tools small" style="height: 80px; margin-top: -18px; padding: 2px 0px 18px 25px;">

    <div class="line-center" :style="'margin-left: -8px; width: 42%; transition: opacity ease-in-out 0.5s; opacity:' + (options.mute ? 0.6 : 1)">
      <font-awesome-icon icon="volume-down" style="margin-top:8px; margin-right:4px; color:#ddd;" />

      <el-slider v-model="sliderGain" @change="gainUpdate()" :min="-36" :max="0"
                 :format-tooltip="function(val) { gainUpdate();  return ((val + 12) + 'db'); }"
                 :marks="{ '-36': '-24db', '-30': '', '-24': '-12db', '-18': '', '-12': '0db', '-6': '', 0: '+12db' }"
                 style="height: 30px; width: 100%;" />

      <font-awesome-icon icon="volume-up" style="margin-top:8px; margin-left:4px; color:#ddd;" />
    </div>

    <div class="line-center" style="margin-left: 16px; margin-right: -12px;">
      <el-tooltip v-for="ppty,item in prms.optButtons" :key="item" effect="dark" :hide-after="2000"
                  :content="(options[item] ? ppty.descOn : ppty.descOff)">

        <div :class="'sbutton small circle ' + ((item == 'mute' && options[item]) || (item == 'meters' && !options[item]) ? 'active' : '')"
             @click="$emit('toggleOption', item)" style="margin: 17px 16px 0px 10px;">
          <font-awesome-icon :icon="ppty.ico" />
        </div>

      </el-tooltip>
    </div>

    <el-tooltip effect="dark" :content="(!playing() ? 'play' : 'stop')" :hide-after="2000">
      <div class="sbutton big circle active" @click="playerToggle()" style="margin: 15px 8px 0px 0px;">
        <font-awesome-icon :icon="(playing() ? 'pause' : 'play')" />
      </div>
    </el-tooltip>
  </div>

  <!-- <el-slider v-model="sliderTone.gain" :min="-90" :max="12" @change="toneUpdate()" style="height: 30px; width: 200px;" /> -->
  <!-- <el-slider v-model="sliderTone.freq" :min="100" :max="5000" @change="toneUpdate()" style="height: 30px; width: 200px;" /> -->
</div>
</template>

<script>
// --------------------------------------------------------------------------------

// audio
/////const volMinDB      = -96;
const volMinDB      = -40;

import AudioLevel from 'worklet-loader!./worklet-splayer-level.js';
//import { sprintf } from 'sprintf-js';
//import sMeter from './smeter.vue'
import sDorrough from './sdorrough.vue'
import { prms } from '../pistache-cfg.js'

export default {
  name: 'sPlayer',

  components: { sDorrough },

  // Property types : String, Number, Boolean, Array, Object, Date, Function, Symbol
  props: {
    displayMode: { type: Number, default: 2 },
    options    : { type: Object, default: function () { return ({ equalizer: false }); } },
  },

  data: function () { return ({
    debug      : 0,
    saveTimer  : null,
    prms       : prms,
    boxWidth   : 0,

    strmAudio  : null,              // decoder stream audio

    player     : {
      ctx         : null,
      intro       : null,           // jingle intro
      source      : null,           // media element (HTML5 audio)
      tone        : null,           // oscillateur
      toneGain    : null,
      biquad      : [],             // peak filters
      analyser    : null,           // analyser audio FFT
      audioLevel  : null,           // worklet audio level
      gain        : null,

      //      script      : null,
      //      worklet     : null,
      // rateCour    : 1,              // playback rate courant
      // deltaTime   : cacheDuration,
      // slowDeltaTime: cacheDuration,
    },

    level         : { rms: [volMinDB, volMinDB], peak: [volMinDB, volMinDB] },  // niveaux audio gauche/droite peak et rms en db
    spectrumCtx   : null,
    curveEqCtx    : null,
    sliderEq      : [0, 0, 0, 0, 0],
    sliderGain    : -12,
    sliderTone    : { freq: 440, gain: -100 },
    // spectrum: { ico: 'chart-bar', desc: 'spectrum analyser' }},
  }); },

  created: function () {
  },

  mounted: function () {
    this.loadOptions ();
    window.addEventListener ('resize', this.onResize);

    this.curveEqCtx = this.$refs.curveEq.getContext('2d');
    ////////////    this.spectrumCtx = this.$refs.spectrum.getContext('2d');

    this.initAudio ();
    this.onResize ();
  },

  computed: {
    // playerInfo: function () {

    //   if (!this.strmAudio || !this.player) { return (''); }

    //   return (sprintf ('%.3f %.3f %s %.3f || %.1f %.1f %.1f', this.player.rateCour, this.player.deltaTime,
    //                    (this.player.deltaTime - this.player.slowDeltaTime > 0 ? '>' : '<'), this.player.slowDeltaTime,
    //                    this.strmAudio.duration, this.strmAudio.currentTime, this.player.ctx.currentTime)
    //          );
    // },
  },

  methods: {

    playing: function () {
      return (this.player.ctx && this.player.source != null);
    },

    onResize: function () {
      if (this.$refs.curveBox) { this.boxWidth = this.$refs.curveBox.clientWidth; }
    },

    initAudio: async function () {
      let that = this;

      if (this.player.ctx != null) { return; }

      // init contexte audio
      let audioContext = undefined;
      try {
        audioContext = window.AudioContext || window.webKitAudioContext;
        if (audioContext == undefined) { return; }
      }
      catch (e) { return; }

      this.player.ctx = new audioContext ({ latencyHint: 'playback', sampleRate: prms.sampleRate });

      const p = this.player;
      this.options.meters = true;
      this.options.audio = true;

      // création objets
      /////      p.source    = p.ctx.createMediaElementSource (this.strmAudio);
      /////      p.intro     = p.ctx.createBufferSource ();
      p.tone      = p.ctx.createOscillator ();  p.tone.channelCountMode = 'explicit';  p.tone.channelCount = 2;
      p.toneGain  = p.ctx.createGain ();
      p.analyser  = p.ctx.createAnalyser ();
      p.gain      = p.ctx.createGain ();
      for (let i = 0;  i < prms.eqFilters.length;  i++) {
        p.biquad[i] = p.ctx.createBiquadFilter ();  p.biquad[i].channelCountMode = 'explicit';  p.biquad[i].channelCount = 2;
      }
      // p.script = p.ctx.createScriptProcessor (audioBlocSize, 0, 2);
      // p.script.onaudioprocess = pScript;

      // worklet AudioLevel
      await p.ctx.audioWorklet.addModule (AudioLevel);
      p.audioLevel = new AudioWorkletNode (this.player.ctx, 'audio-level');
      p.audioLevel.parameters.get ('eventDivFactor').setValueAtTime (10, 0);
      //console.log (p.audioLevel);

      p.audioLevel.port.onmessage = function (event) {
               // console.log ('received message from worklet ', event.data);
        if (!that.options.meters) {
          for (let i = 0;  i < 2;  i++) { that.$refs.meter[i].update (-96, -96); }
          return;
        }

        for (let i = 0;  i < 2;  i++) {
          that.level.peak[i] = event.data.levels.peak[i];
          that.level.rms[i] = event.data.levels.rms[i];
          that.$refs.meter[i].update (that.level.rms[i], that.level.peak[i]);
        }
      }
      for (let i = 0;  i < 2;  i++) { that.$refs.meter[i].update (-96, -96); }

      // config tone
      p.tone.type = 'sine';  p.tone.channelCount = 2;  p.tone.channelCountMode = 'explicit';  this.toneUpdate ();  p.tone.start (0);

      // config analyser
      p.analyser.fftSize = 2048;  p.analyser.smoothingTimeConstant = 0.95;

      // config biquad
      for (let i = 0;  i < prms.eqFilters.length;  i++) {
        p.biquad[i].type = prms.eqFilters[i].t;   p.biquad[i].frequency.value = prms.eqFilters[i].f;  p.biquad[i].Q.value = prms.eqFilters[i].q;
        p.biquad[i].gain.value = 1;
      }

      // config gain
      p.gain.gain.value = 1;

      // connexions
      p.tone.connect (p.toneGain);  p.toneGain.connect (p.biquad[0]);  p.toneGain.connect (p.audioLevel);
      //////p.source.connect (p.biquad[0]);
      for (let i = 1;  i < prms.eqFilters.length;  i++) { p.biquad[i-1].connect (p.biquad[i]); }
      p.biquad[prms.eqFilters.length - 1].connect (p.gain);
      //      p.biquad[prms.eqFilters.length - 1].connect (p.splitter);
      p.gain.connect (p.ctx.destination);

      // maj filtres & gain
      for (let i = 0;  i < prms.eqFilters.length;  i++) { this.player.biquad[i].gain.value = this.sliderEq[i]; }

      if (this.options.equalizer) { this.drawCurveEq (); }
      this.gainUpdate ();
    },

    playerToggle: function () {

      if (this.playing()) {

        let that = this;
        this.player.gain.gain.setTargetAtTime (0, this.player.ctx.currentTime, 0.1);
        setTimeout (function () { that.playerStop (); }, 200);
      }
      else { this.playerStart (); }
    },

    playerStop: function () {

      if (this.player.ctx) {

        if (this.player.source) {
          this.player.source.disconnect (this.player.biquad[0]);  this.player.source.disconnect (this.player.audioLevel);
          this.player.source = null;
        }
        if (this.player.intro) {
          this.player.intro.stop ();  this.player.intro = null;
        }
      }
      if (this.strmAudio) { this.strmAudio.pause ();  this.strmAudio = null; }
      //      this.player.ctx.suspend ();
    },

    // init player audio en lecture
    playerStart: async function() {

      this.playerStop ();

      // init HTML5 Audio
      const s = this.strmAudio = new Audio ();

      s.crossOrigin = false;
      // s.preload = 'none';     // tentative désactivation buffer (nop)
      // s.load ();              // tentative flush buffer (=> nop)
      // s.src = 'about:blank';  // tentative flush buffer (=> nop)

      // pb buffering (non désactivable ???) => génération d'une URL différente à chaque lancement :(
      s.controls = false;
      s.autoplay = false;
      s.src = prms.sourceURL.web + (new Date ().toISOString());

      // debug
      // s.onplaying = function () { console.log ('playing'); }
      // s.onstalled = function () { console.log ('stalled');  s.pause (); }
      // s.onemptied = function () { console.log ('empty');  s.pause (); }
      // s.onseeking = function () { console.log ('seeking'); }
      // s.onstatechange  = function () { console.log ('change'); }
      // s.onwaiting  = function () { console.log ('wait'); }
      // s.oncanplaythrough = function () { console.log ('canplaythrough'); }

      const p = this.player;

      if (p.ctx) {

        // connexion source audio HTML5
        p.source = p.ctx.createMediaElementSource (s);
        p.source.connect (p.biquad[0]);  p.source.connect (p.audioLevel);
        p.ctx.resume ();

        // jingle d'intro
        let req = new XMLHttpRequest ();
        req.open ('GET', prms.introURL, true);  req.responseType = 'arraybuffer';
        req.onload = function () {

          setTimeout (function () { s && s.play (); }, 3700);

          let data = req.response;
          p.ctx.decodeAudioData (data, function (buffer) {

            p.intro = p.ctx.createBufferSource ();
            p.intro.connect (p.biquad[0]);  p.intro.connect (p.audioLevel);  p.intro.buffer = buffer;  p.intro.start (0);

          }, function (e) { console.log (e); })
        };
        req.send ();
      }
      else {
        s.play ();
        s.volume = 0.2;
      }

      //      console.log (navigator);
      //      liste des sorties disponibles (https only) - liste incomplète ?
      // const devices = navigator.mediaDevices.enumerateDevices ()
      //       .then (function (devices) {
      //         let audioDevices = devices.filter (function (dev) { return (dev.kind == 'audiooutput'); });
      //         console.log (audioDevices);
      //       })
      //       .catch (function (err) {
      //         console.log ('ERREUR ' + err);
      //       });
      // // ctx.setSinkId(audioDevices[0].deviceId)

      // audio level (worklet)
      // await p.ctx.audioWorklet.addModule (AudioLevel);
      // p.audioLevel = new AudioWorkletNode (p.ctx, 'audio-level');
      // p.audioLevel.parameters.get ('eventDivFactor').setValueAtTime (1000, 0);

      // console.log (p.audioLevel);
      // p.audioLevel.port.onmessage = function (event) {
      //   console.log ('received message from worklet ', event.data);
      // }

      // // preparation canvas pour affichage analyser
      // const canvas = this.$refs['anaylyserCanvas'];
      // this.spectrumCtx = canvas.getContext ('2d');

      // let frameLoop1 = function (timestamp) {
      //   window.requestAnimationFrame (frameLoop1);

      //   that.player.analyser.getByteTimeDomainData (dataArray);

      //   that.spectrumCtx.fillStyle = "rgb(0, 0, 0)";
      //   that.spectrumCtx.fillRect (0, 0, canvas.width, canvas.height);

      //   that.spectrumCtx.lineWidth = 2;
      //   that.spectrumCtx.strokeStyle = "rgb(255, 100, 0)";

      //   that.spectrumCtx.beginPath ();

      //   let sliceWidth = canvas.width * 2.0 / bufferLength;
      //   let x = 0;

      //   for (let i = 0; i < bufferLength; i++) {

      //     let v = dataArray[i] / 128.0;
      //     let y = v * canvas.height / 2;

      //     if (i === 0) { that.spectrumCtx.moveTo (x, y); } else { that.spectrumCtx.lineTo (x, y); }
      //     x += sliceWidth;
      //   }
      //   that.spectrumCtx.lineTo (canvas.width, canvas.height / 2);
      //   that.spectrumCtx.stroke ();
      // };
    },

//     levelMetering: function (channel) {

//       let that = this;

//       const bufferLength = that.player.levAna[channel].frequencyBinCount;
//       let dataArray = new Uint8Array (bufferLength);

//       let frameLoop = function (timestamp) {
//         window.requestAnimationFrame (frameLoop);

//         that.player.levAna[channel].getByteTimeDomainData (dataArray);

//         let x = 0;
//         // for (let i = 0; i < bufferLength; i++) { x += Math.abs(dataArray[i] - 128); }

//         // recherche valeur max sur échantillon
//         for (let i = 0; i < bufferLength; i++) {
//           const val = dataArray[i] - 128;
//           if (x < val) { x = val; }
//         }

//         // conversion val moy
// ///        x = x / bufferLength;
//         if (timestamp % 2 == 0) {
//           console.log (x, that.level.peak[channel], that.level.rms[channel], channel, bufferLength, that.player.ctx.state);
//           that.level.peak[channel] = 0;
//         }

//         // conversion en db
//         if (x > 0) { x = Math.max (volMinDB, 20 * Math.log (x / 128)); } else { x = volMinDB; }

//         // peak attack
//         if (x > that.level.peak[channel]) { that.level.peak[channel] = x; }

//         // peak release
//         else {
//           that.level.peak[channel] = Math.max (that.level.peak[channel] - that.timing.peakRelease * bufferLength, volMinDB);
//         }

//         // rms attack
//         if (x > that.level.rms[channel]) {
//           that.level.rms[channel] = that.level.rms[channel] + (x - that.level.rms[channel]) * that.timing.rmsAttack * bufferLength;
//         }
//         // rms release
//         else {
//           that.level.rms[channel] = Math.max (that.level.rms[channel] - that.timing.rmsRelease * bufferLength, volMinDB);
//         }

//         // mise à jour vu-mètre
//         if (that.$refs.meter) { that.$refs.meter[channel].update (that.level.peak[channel]); }
//       }

//       frameLoop (0);
//     },

    spectrumMetering: function () {

      let that = this;

      const canvas = this.$refs['analyserCanvas'];
      this.spectrumCtx = canvas.getContext ('2d');

      const bufferLength = that.player.analyser.frequencyBinCount
      let fbcArray = new Uint8Array (bufferLength);

      that.spectrumCtx.fillStyle = "rgb(0, 0, 0)";
      that.spectrumCtx.fillRect (0, 0, canvas.width, canvas.height);

      let frameLoop = function (timestamp) {
        window.requestAnimationFrame (frameLoop);

        that.player.analyser.getByteFrequencyData (fbcArray);

        that.spectrumCtx.fillStyle = "rgb(0, 0, 0)";
        that.spectrumCtx.fillRect (0, 0, canvas.width, canvas.height);

        that.spectrumCtx.lineWidth = 2;
        that.spectrumCtx.strokeStyle = "rgb(255, 100, 0)";

        that.spectrumCtx.beginPath ();

        let sliceWidth = canvas.width * 5.0 / bufferLength;
        let x = 0;

        for (let i = 0; i < bufferLength; i++) {

          let v = fbcArray[i] / 128.0;
          let y = v * canvas.height / 2;

          if (i === 0) { that.spectrumCtx.moveTo (x, y); } else { that.spectrumCtx.lineTo (x, y); }
          x += sliceWidth;
        }
        that.spectrumCtx.lineTo (canvas.width, canvas.height / 2);
        that.spectrumCtx.stroke ();
      };

      frameLoop (0);
    },

    // gestion adaptative samplerate => abandon : pb détection niveau remplissage courant du buffer d'entrée
    // setInterval (function () {

    //   if (!that.player.ctx || !that.strmAudio) { return; }
    //   if (that.player.ctx.currentTime === Infinity || that.strmAudio.duration === Infinity) { return; }

    //   const deltaCour = that.strmAudio.duration - that.strmAudio.currentTime;

    //   that.player.deltaTime += (deltaCour - that.player.deltaTime) / 5;
    //   that.player.slowDeltaTime += (that.player.deltaTime - that.player.slowDeltaTime) / 20;

    //   if ((that.player.deltaTime < cacheDuration) && (that.player.deltaTime < that.player.slowDeltaTime)) {
    //     if (that.player.rateCour > 0.98) { that.player.rateCour += 0.00001 * (that.player.deltaTime - cacheDuration); }
    //   }

    //   if ((that.player.deltaTime > cacheDuration) && (that.player.deltaTime > that.player.slowDeltaTime)) {
    //     if (that.player.rateCour < 1.02) { that.player.rateCour +=  0.00001 * (that.player.deltaTime - cacheDuration); }
    //   }

    //   that.strmAudio.playbackRate = that.player.rateCour;
    // }, 100);


    //   // --------------------------------------------------------------------------------
    //   return;

    //   /* exemple audioworkletprocessor */
    //   await this.decoder.ctx.audioWorklet.addModule (DecoderFetch);
    //   this.decoder.ctx.worklet = new AudioWorkletNode (this.decoder.ctx, 'decoder-fetch');

    //   this.decoder.source.connect (this.decoder.ctx.worklet);
    //   this.decoder.ctx.worklet.connect (this.decoder.ctx.destination);

    //   //      this.ctx.onstatechange  = function () { console.log ('ctx change'); }
    //   //      this.frameLoop ();
    //   // p.script = this.ctx.createScriptProcessor (audioBlocSize, 0, 2);
    //   // p.script.onaudioprocess = pScript;
    //   // //      console.log(p.script.bufferSize);

    //   // //      p.src.connect(p.script);
    // },

    //     decoderScript: function (processingEvent) {
    //       if (!this.decoder.ctx) { return; }

    //       let inputBuffer = processingEvent.inputBuffer;

    //       for (let channel = 0;  channel < 2;  channel++) {
    //         let inputData = inputBuffer.getChannelData (channel);

    //         if (this.audioChunks[channel].length < maxChunks) { this.audioChunks[channel].push (inputData); }
    //       }
    //     },

    //     playerScript: function (processingEvent) {
    //       if (!this.player.ctx) { return; }

    //       let outputBuffer = processingEvent.outputBuffer;

    //       for (let channel = 0;  channel < 2;  channel++) {
    //         let outputData = outputBuffer.getChannelData (channel);

    //         if (this.audioChunks[channel].length > 0) {
    //           const buff = this.audioChunks[channel].shift ();

    //           for (let sample = 0;  sample < outputBuffer.length;  sample++) { outputData[sample] = buff[sample] * 0.3; }
    // //          for (let sample = 0;  sample < outputBuffer.length;  sample++) { outputData[sample] = inbuf[sample]; }
    //         }
    //       }
    //     },

    drawCurveEq: function () {

      const dbScale    = 18;
      const yOffset    = 25;
      const yOffsetR   = 5;
      const xOffset    = 20;
      const xOffsetR   = 48;

      const curveColor = '#f80';
      const gridColor  = '#555';
      const db0Color   = '#777';
      const textColor  = '#eee';

      const nbOctaves  = 10;
      const nyquist    = 20000;
//      const freqs      = [30, 60, 120, 250, 500, 1000, 2000, 4000, 8000, 16000];

      const cv = this.$refs.curveEq;
      const ctx = this.curveEqCtx;

      ctx.clearRect (0, 0, cv.width, cv.height);

      let pixelsPerDb = (0.5 * (cv.height - yOffsetR)) / dbScale;

      let freqHz = new Float32Array (1);
      let magResp = new Float32Array (1);
      let phaseResp = new Float32Array (1);

      // affichage échelle des fréquences
      ctx.beginPath ();  ctx.lineWidth = 1;

      // text
      ctx.font = 'Arial';  ctx.fillStyle = textColor;  ctx.textAlign = 'center';  ctx.strokeStyle = gridColor;

      for (let i = 0;  i <= nbOctaves;  i++) {

        let x = xOffset + i * ((cv.width - xOffset - xOffsetR) / nbOctaves);
        ctx.moveTo (x, yOffset);  ctx.lineTo (x, cv.height - yOffsetR);  ctx.stroke ();

        // text
        let f = nyquist * Math.pow (2.0, i - nbOctaves);
        let unit = 'Hz';  let value = f.toFixed (0);
        if (f >= 1000) {
          unit = 'Khz';  value = (value / 1000);
          if (f >= 1000) { value = value.toFixed (0); } else { value = value.toFixed (1); }
        }

        ctx.fillText (value + unit, x, yOffset - 8);
      }

      // affichage échelle de niveaux en db
      ctx.beginPath ();  ctx.lineWidth = 1;  ctx.strokeStyle = gridColor;  ctx.fillStyle = textColor;  ctx.textAlign = 'center';

      for (let i = -3;  i <= 3;  i++) {

        let y = yOffset + (cv.height - yOffset - yOffsetR) * (0.5 + i * 6 / (cv.height - yOffsetR) * pixelsPerDb);
        ctx.moveTo (xOffset - 10, y);  ctx.lineTo (cv.width - xOffsetR + 10, y);

        ctx.fillText (-i * 6 + 'db', cv.width - xOffsetR + 33, y + 3);
      }
      ctx.stroke ();

      // ligne 0bd
      ctx.beginPath ();  ctx.lineWidth = 2;  ctx.strokeStyle = db0Color;
      ctx.moveTo (xOffset - 10, yOffset + (cv.height - yOffset - yOffsetR) * 0.5);
      ctx.lineTo (cv.width - xOffsetR + 10, yOffset + (cv.height - yOffset - yOffsetR) * 0.5);
      ctx.stroke ();

      // affichage courbe réponse filtres
      ctx.strokeStyle = curveColor;  ctx.lineWidth = 2;  ctx.beginPath ();  ctx.moveTo (0, 0);

      for (let i = 0;  i < cv.width - xOffset - xOffsetR;  i++) {

        freqHz[0] = nyquist * Math.pow (2, nbOctaves * (i / (cv.width - xOffset - xOffsetR) - 1.0));

        let dbResp = 0;
        for (let i = 0;  i < prms.eqFilters.length;  i++) {

          if (this.player.ctx != null) {
            this.player.biquad[i].getFrequencyResponse (freqHz, magResp, phaseResp);
            dbResp += 20 * Math.log(magResp[0]) / Math.LN10;
          }
          else { dbResp = 0; }
        }

        let y = yOffset + (0.5 * (cv.height - yOffset - yOffsetR)) * (1 - dbResp / dbScale);

        if (i == 0) { ctx.moveTo (xOffset, y); } else { ctx.lineTo (xOffset + i, y); }
      }
      ctx.stroke ();

    },

    eqFilterUpdate: function (idx) {
      let that = this;

      if (!this.options.equalizer) { return; }
      this.boxWidth = this.$refs.curveBox.clientWidth;

      if (this.player.ctx && this.player.biquad[idx]) {
        this.player.biquad[idx].gain.value = this.sliderEq[idx];
//        console.log ('eqFilterUpdate', idx, this.player.biquad[idx].gain.value);
        this.drawCurveEq ();
      }
      this.$nextTick (function () { that.saveOptions (); });
    },

    gainUpdate: function () {
      let that = this;

      if (this.player.ctx && this.player.gain) {
        if (this.options.mute) { this.player.gain.gain.setTargetAtTime (0, this.player.ctx.currentTime, 0.1); }
        else { this.player.gain.gain.setTargetAtTime (Math.exp (this.sliderGain / 20 * Math.LN10), this.player.ctx.currentTime, 0.); }
      }
      this.$nextTick (function () { that.saveOptions (); });
    },

    toneUpdate: function () {

      if (this.player.ctx && this.player.tone) {
        this.player.toneGain.gain.value = Math.exp (this.sliderTone.gain / 20 * Math.LN10);
         this.player.tone.frequency.value = this.sliderTone.freq;
      }
    },

    eqButtonClick: function (btn) {

      if (prms.eqPresets[btn]) {
        this.sliderEq = [];
        for (let i = 0;  i < prms.eqFilters.length;  i++) { this.sliderEq[i] = prms.eqPresets[btn][i];  this.eqFilterUpdate (i); }
      }
    },

    // chargement des paramètres audio depuis stockage local du navigateur
    loadOptions: function () {

      try {
        const opts = JSON.parse (localStorage.pistache_audio);
        if (!opts) { return; }

        if (opts.gain != undefined && typeof(opts.gain) == 'number') { this.sliderGain = opts.gain; }
        if (opts.sliderEq != undefined && Array.isArray(opts.sliderEq)) { this.sliderEq = opts.sliderEq; }
      }
      catch (e) { localStorage.clear ();  this.eqButtonClick ('default'); }
    },

    // sauvegarde des paramètres audio dans stockage local du navigateur
    saveOptions: function () {

      if (this.saveTimer) { clearTimeout (this.saveTimer); }
      let that = this;

      this.saveTimer = setTimeout (function () {

        that.saveTimer = null;
        localStorage.pistache_audio = JSON.stringify ({ gain: that.sliderGain, sliderEq: that.sliderEq });
      }, 1000);
    },
  },
}

// --------------------------------------------------------------------------------

</script>


<style lang="scss" scoped>

.buttons-opt {
  width: 38px;
  height: 38px;
  margin-right: 10px;
  margin-top: 16px;
}

</style>
